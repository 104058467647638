<template>
<div class="search">
  <div class="top">
    <van-search placeholder="请输入关键词搜索" clearable v-model="st" shape="round" show-action>
      <template #action>
        <div style="color: #105DF2;" @click="onSearch">搜索</div>
      </template>
    </van-search>
  </div>
  <div class="content" v-if="list != null && list.length">
    <van-cell v-for="item in list" :title="item.name" @click="onSelect(item)"></van-cell>
  </div>
  <iframe src="error?type=1" v-if="list != null" class="iframe" />
</div>
</template>

<script setup>
import {ref,computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import axios from "../../axios/index.js";
import tools from "../../utils/tools.js";
import {formatSelect, showLoading} from "../../utils/utils.js";
import {useStore} from "vuex";
const router = useRouter();
const route = useRoute();
const store = useStore();
const type = Number(route.query.type || 0); // 0 预报名 1 直接报名
const level = Number(route.query.level || 2); // 1 小学 2 初中 3 高中
const title = route.query.title;
if (title){
  tools.setWxTitle(decodeURIComponent(title));
}
// 搜索内容
const st = ref("");
// 行政编码
const code = computed(()=>{
  let code = route.query.code;
  if (!code)return undefined;
  if (!type){
    return code;
  }else if (type === 1) {
    let formData = store.getters.formDataCache;
    return formData[code][2].value;
  }
});
// 列表
const list =  ref(null)
// 初始化搜索全部数据
onSearch();
// 搜索事件
function onSearch(){
  let search = {
    name:st.value,
    level
  };
  if (type === 1){
    search.area = code.value;
  }else search.city = code.value;
  let _s = Object.keys(search)
      .filter(key=> {
        return !tools.isEmpty(search[key])
      })
      .reduce((item,key)=>{
        return ({...item,[key]:search[key]})
      }, {})
  showLoading(true);
  axios.get("/api/recruit/common/junior-high-school",{
    params:_s
  }).then(res=>{
    list.value = res;
    showLoading(false)
  }).catch(err=>{
    list.value = [];
    showLoading(false)
  })
}
// 选中事件
function onSelect(item){
  if (!type)
    store.commit("setRegisterCache",{
      student_attend_graduation_school: {
        text:item.name,
        value:item.id
      },
      student_attend_graduation_school_jc:item.local_name
    });
  else if (type === 1){
    const prop = route.query.prop;
    store.commit("setFormDataCache",{
      [prop]: {
        text:item.name,
        value:item.id
      }
    });
  }
  router.back();
}
</script>

<style lang="scss" scoped>
.search{
  .top{
    height: 54rem;
    border-bottom: 1px solid #eee;
  }
  .content{
    height: calc(100vh - 80rem);
    padding: 12rem;
  }
  iframe.iframe{
    height: calc(100vh - 80rem);
    border: none;
    width: 100%;
  }
}
</style>
