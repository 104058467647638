import constants from "../utils/constants.js";
import {setCache} from "../utils/utils.js";
import tools from "../utils/tools.js";
import {formUtils} from "../utils/utils.js";

export default {
    // 加载中状态
    setLoading(state , payload){
        state.loading = payload;
    },
    // 加载中状态
    setLoadingText(state , payload){
        state.loadingText = payload;
    },
    // 注册时缓存
    setSignupCache(state,payload){
       state.signupCache = setCache(constants.signupCache, payload);
    },
    // 民族数据源缓存
    setNationSource(state,payload){
       state.nationSource = setCache(constants.nationSource, payload);
    },
    // 地区数据源缓存
    setAddressSource(state,payload){
       state.addressSource = setCache(constants.addressSource, payload);
    },
    // 注册数据缓存
    setRegisterCache(state,payload){
        state.registerCache = setCache(constants.registerCache, payload);
    },
    // 基础数据缓存
    setBaseConfig(state,payload){
        state.baseConfig = setCache(constants.baseConfig, payload);
    },
    // 用户注册信息
    setUserBaseInfo(state,payload){
        state.userBaseInfo = setCache(constants.userBaseInfo, payload);
    },
    // 用户注册信息
    setRegisterInstance(state,payload){
        state.registerInstance = payload;
    },
    // 进度条
    setProgress(state,payload){
        state.progress = payload;
    },
    // 表单数据缓存
    setFormDataCache(state,payload){
        if (typeof payload === "number")
            switch (payload){
                case 0:
                    state.formDataCache = setCache(constants.formDataCache,{},true,true);
                    break;
            }
        else
        state.formDataCache = setCache(constants.formDataCache,payload);
    },
    // 表单配置缓存
    setFormConfigCache(state,payload){
        state.formConfigCache = setCache(constants.formConfigCache,formUtils.formatConfig(payload),false,true);
    },
    // 表单配置额外数据缓存
    setFormExtraCache(state,payload){
        state.formExtraCache = setCache(constants.formExtraCache,formUtils.formatConfig(payload),false,false);
    },
    // 进度条
    setIdCard(state,payload){
        state.idCard = payload;
        tools.setSession(constants.idCard,payload);
    },
    // 进度条
    setKeepList(state,payload){
        state.keepList = payload;
        tools.setSession(constants.keepList,payload,true,true);
    },
    setMicroMessenger(state,payload){
        state.microMessenger = setCache(constants.microMessenger,payload,false)
    },
    setPathInfo(state,payload){
        payload = payload || state.pathInfo;
        state.pathInfo = setCache(constants.pathInfo,payload,true);
    }
}
