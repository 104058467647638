import config from "./config.js"
import lhy from "./lhy.js";
import ycj from "./ycj.js";
import wcl from "./wcl.js";
import Error from "../pre-pages/Error/index.vue"
import * as VRouter from "vue-router"
import store from "../store"
import {checkBrowser} from "../utils/utils.js";

import tools from "../utils/tools.js";
import Home from "../pre-pages/Home/index.vue";

let routes = [
    {
        path:"/",
        name:"home",
        meta:{
            title:"请稍后...",
        },
        component:Home
    },
    {
        path:"/error",
        name:"error",
        component:Error,
        meta:{
            check:false,
            title:"错误"
        }

    },
    ...lhy,
    ...wcl,
    ...ycj,
    ...config
];
const router = VRouter.createRouter({
    routes,
    history:VRouter.createWebHistory()
});
router.beforeEach((to, from, next)=>{
    let title = to.meta.title;
    let check = to.meta.check !== false;
    if (!to.meta.onHistory)window.onpopstate = null;
    if (to.meta.keepAlive){
        // setToKeepList(to.name);
    }
    if (!!title)
        tools.setWxTitle(title);

    next();
})
router.afterEach((to)=>{

})
export default router;
