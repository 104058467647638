<template>
  <t-cascader
              :placeholder="placeholder"
              :model-value="modelValue"
              @update:model-value="$emit('update:modelValue',$event)"
              :options="addressOptions"
              :title="title"
              @change="onAddressChange"
              @onClick="$emit('click',$event)"
              :cascader-props="cascaderProps" />
</template>

<script>
import {Toast} from "vant";
import {asyncGetAddressInfo} from "../../utils/api.js";

export default {
  name: "TAddress",
  emits:["update:modelValue","change","confirm","cancel","onClick"],
  props:{
    modelValue:[String,Object,Number],
    title:String,
    placeholder:String,
    options:Object,
    // vant 官方picker 属性
    cascaderProps:Object
  },
  data(){
    return {
      addressOptions:[]
    }
  },
  mounted() {
    this.getAddress();
  },
  methods:{

    onAddressChange(e){
      this.$emit('change',e);
      e.selectedOptions.children = [];
      if (e.tabIndex < 2){
        this.getAddress(e);
      }
    },
    getAddress(event){
      let {value,tabIndex} = event || {};
      let loading = Toast.loading({
        message:"请稍后",
        duration:0,
        forbidClick:true
      })
      asyncGetAddressInfo(value).then(res=>{
        this.setOption(res,event);
      })
          .finally(()=>loading.close())
    },
    setOption(result,event){
      let res = result.map(item=>{
        let opt = {
          text:item.address_name,
          value:item.address_code,
          children:[]
        }
        if (event && event.tabIndex === 1) delete opt.children;
        return opt;
      });
      if (!event)this.addressOptions= res;
      else {
        let {value,tabIndex,selectedOptions} = event;
        this.$tools.DFS(this.addressOptions,(item)=>{
          if (item.value === value)item.children = res;
        },{
          child:"children"
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
