<template>
  <div class="t-result">
    <t-img :src="img" v-bind="imgProps"/>
    <slot name="msg"></slot>
    <div class="hint" :style="hintStyle">{{hint}}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TResult",
  props:{
    img:String,
    imgProps:Object,
    hint:String,
    msg:String,
    hintStyle:[String,Object]
  }
}
</script>

<style scoped lang="scss">
.t-result{
  padding: 60rem 40rem;
  text-align: center;

  img{
    width: 236rem;
    height:172rem;
  }
  .hint{
    margin: 31rem 0 46rem 0;
    font-size: 16rem;
  }
}
</style>
