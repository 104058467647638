<template>
  <t-result :img="img" :hint="hint" :imgProps="{width:(width||166)+'rem',height:(height||140)+'rem'}" hintStyle="color:#7C7C7C">
  </t-result>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute()
const type = Number(route.query.type);
const {width,height} = route.query;
let img = ""
let hint = ""
switch (type) {
  case 0:
    hint = "网络丢失了";
    img = "err-00.png"
    break;
  case 1:
    hint = "暂无数据";
    img = "err-01.png"
    break;
  case 2:
    hint = "加载失败";
    img = "err-02.png"
    break;
  case 3:
    hint = "请在微信客户端打开链接";
    img = "err-03.png"
    break;
  case 4:
    hint = "请使用微信扫描二维码";
    img = "err-04.png"
    break;
}

</script>

<style lang="scss" scoped>

</style>
