export const step1 = [
  {
    "key": "请填写监护人的身份信息",
    "type": "",
    "placeholder": ""
  },
  {
    "key": "监护人姓名",
    "type": "input",
    "placeholder": "请输入",
    "prop":"parent_name",
    property:{
      maxlength:30
    },
    reg:/^([\u4e00-\u9fa5]|[a-zA-Z·.])+$/
  },
  {
    "key": "监护人身份证号",
    "type": "input",
    "placeholder": "请输入",
    "prop":"parent_idcard",
    property: {
      maxlength: 18,
      type:"id"
    },
    reg:"id"
  }
]
export const step1_2 = [
  {
    "key": "咨询学生身份信息",
    "type": "",
    "placeholder": ""
  },
  {
    "key": "学生姓名",
    "type": "input",
    "placeholder": "请输入",
    "prop":"student_name",
    property:{
      maxlength:30
    },
    reg:/^([\u4e00-\u9fa5]|[a-zA-Z·.])+$/
  },
  {
    "key": "学生身份证号",
    "type": "input",
    "placeholder": "请输入",
    "prop":"student_idcard",
    property: {
      maxlength: 18,
      type:"id"
    },
    reg:"id"
  }
]
export const step2 = [
  {
    "key": "学生基础信息",
    "type": "",
    "placeholder": "",
  },
  {
    "key": "学生民族",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_nation",
    "router":"/nation-list",
  },
  {
    "key": "学生户籍地址",
    "type": "",
    "placeholder": "",
    "subTitle": [
      "部分地区存在高考加分政策，请严格按照学生个人身份证信息填写"
    ],
  },
  {
    "key": "省份",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_register_province",
    "router":"/address-list",
  },
  {
    "key": "城市",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_register_city",
    "router":"/address-list",
  },
  {
    "key": "选择区/县",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_register_area",
    "router":"/address-list",
  },
  {
    "key": "学生通信地址",
    "type": "",
    "placeholder": "",
    "subTitle": [
      "原则上户籍地址与学生通信地址一致，存在部分学生户籍地址与",
      "学生通信地址不一致情况"
    ],
  },
  {
    "key": "省份",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_communication_province",
    "router":"/address-list",
  },
  {
    "key": "城市",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_communication_city",
    "router":"/address-list",
  },
  {
    "key": "选择区/县",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    "readonly":true,
    "prop":"student_communication_area",
    "router":"/address-list",
  },
  {
    "key": "详细地址",
    "type": "input",
    "prop":"student_communication_address",
    "placeholder": "请输入",
    property:{
      maxlength:50
    }
  }
]
export const step3 = [
  {
    "key": "学生初中就读情况",
    "type": "",
    "placeholder": ""
  },
  {
    "key": "学生就读途径",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"student_attend_road"
  },
  {
    "key": "初中毕业年份",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"student_attend_graduation"
  },
  {
    "key": "初中毕业省份",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    readonly:true,
    prop:"student_attend_graduation_province",
    router:"/address-list"
  },
  {
    "key": "初中毕业城市",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    readonly:true,
    prop:"student_attend_graduation_city",
    router:"/address-list"
  },
  {
    "key": "初中毕业学校",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    readonly: true,
    router:"/2024-online/school-of-graduation/list",
    prop:"student_attend_graduation_school"
  },
  {
    "key": "初中毕业班级",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"student_attend_graduation_class"
  },
  {
    "key": "初中是否留级",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"student_attend_repetition"
  },
  {
    "key": "初中是否休学",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"student_attend_suspend"
  }
]
export const step4 = [
  {
    "key": "学生中考成绩预估",
    "type": "",
    "placeholder": "",
    "subTitle":["按学生初中学籍所在地市州中考成绩填报即可，无需换算为就读高中所在地市州的中考分数。"]
  },
  {
    "key": "",
    "type": "input",
    "placeholder": "请输入",
    prop:"student_senior_achievement",
    property:{
      maxlength:10
    }
  },
  {
    "key": "高中学习发展期望",
    "type": "",
    "placeholder": ""
  },
  {
    "key": "高中学习外语语种",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"student_high_languages"
  },
  {
    "key": "高中学习方向",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"study_type"
  },
  {
    "key": "高中专业方向",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"major",
    readonly: true
  },
  {
    "key": "期望报名学校",
    "type": "",
    "placeholder": ""
  },
  {
    "key": "",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    prop:"school_id"
  },
  {
    "key": "报名咨询老师",
    "type": "",
    placeholder: ""
  },
  {
    "key": "",
    "type": "select",
    "placeholder": "请选择",
    "options": [],
    readonly: true,
    router:"/register-teacher-list-2",
    prop:"teacher_id"
  },
];
export  default [step1,step1_2,step2,step3,step4]
/*
parent_name		                        家长姓名
parent_idcard	                        家长身份证号码
student_name	                  	    学生姓名
student_idcard	                  	    学生身份证号码
student_nation		                    学生民族
student_register_province		        学生户籍地址-省份
student_register_city		            学生户籍地址-城市
student_register_area		            学生户籍地址-区县
student_communication_province		    学生通讯地址-省份
student_communication_city		        学生通讯地址-城市
student_communication_area		        学生通讯地址-区县
student_communication_address		    学生通讯地址-详细地址
student_attend_road		                学生就读途径
student_attend_graduation		        初中毕业年份
student_attend_graduation_province	    初中毕业省份
student_attend_graduation_city		    初中毕业城市
student_attend_graduation_school	    初中毕业学校
student_attend_graduation_class		    初中毕业班级
student_attend_repetition		        初中是否留级
student_attend_suspend		            初中是否休学
student_senior_achievement		        中考成绩估计
student_high_languages		            高中学习语种
student_high_direction		            高中学习方向
school_id		                        期望报名学校
phone		                            报名手机号码
code		                            验证码
*/
