<!-- 已报名订单详情 -->
<template>
  <div class="order-info">
    <div class="order">
      <div class="top-bg">
        <div class="bar">
          <div class="line">

          </div>
        </div>
      </div>
      <div class="content">
        <t-order :data="middleData" class="box">
          <template #header>
            <t-order :data="topData" class="header"></t-order>
          </template>
          <template #footer>
            <t-order :data="bottomData" class="footer"></t-order>
          </template>
        </t-order>
      </div>
    </div>

    <div class="btns">
      <t-button :block="false" @click="locationTo">查看协议内容</t-button>
      <t-button :block="false" @click="$router.push('/order-official?id=' + route.query.id)">查看正式报名费用</t-button>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {Dialog} from "vant";
import {useRoute, useRouter} from "vue-router";
import axios from "../../axios/index.js";

const router = useRouter();
const route = useRoute();
const hasOrder = ref(true);
const status = route.query.status && Number(route.query.status);

function formatData(data) {
  let res = {};
  data.forEach(item => {
    res[item.key] = item.val.join(" ");
  })
  return res;
}

// let topData, middleData, bottomData;
let topData = ref({})
let middleData = ref({})
let bottomData = ref({})
let url = ref('')

function getData() {
  axios.post('/api/recruit/family/order-details', {order_number: route.query.id}).then(res => {
    const topResource = [
      {"key": "服务名称", "val": [res.service_name]},
      {"key": "报名学校", "val": [res.school_name]},
      {"key": "报名学校顺序号", "val": [res.school_sn]}
    ];
    const middleResource = [
      {"key": "监护人姓名", "val": [res.parent_name]},
      {"key": "报名手机号", "val": [res.parent_phone]},
      {"key": "学生姓名", "val": [res.student_name]},
      {"key": "咨询老师", "val": [res.teacher_name]},
      {"key": "实际支付金额", "val": ['￥'+(res.money/100).toFixed(2)]},
      {"key": "支付方式", "val": [res.pay_name]},
      {"key": "协议名称", "val": [res.agreement_name]},
      {"key": "协议编号", "val": [res.agreement_sn]},
      {"key": "报名时间", "val": [res.sign_time]},
      {"key": "订单号", "val": [res.order_number]},
      {"key": "支付订单号", "val": [res.order_m]},
      {"key": "支付成功时间", "val": [res.pay_time]}
    ];
    const bottomResource = [
      {"key": "学生民族", "val": [res.student_nation]},
      {"key": "重大疾病史", "val": [res.physiology]},
      {"key": "心理健康问题", "val": [res.psychology]},
      {"key": "犯罪前科", "val": [res.crime]},
      {"key": "学生户籍地", "val": [res.student_residence]},
      {"key": "学生通信地址", "val": [res.student_communication]},
      {"key": "邮政编码", "val": [res.student_postal_code]},
      {"key": "监护人关系", "val": [res.relationship]},
      {"key": "学生初中毕业时间", "val": [res.student_attend_graduation]},
      {"key": "就读途径", "val": [res.student_attend_road]},
      {"key": "学生初中毕业学校", "val": [res.student_attend_graduation_school]},
      {"key": "学生初中毕业学校班级", "val": [res.student_attend_graduation_class]},
      {"key": "初中是否休学过", "val": [res.student_attend_suspend]},
      {"key": "初中是否留级过", "val": [res.student_attend_repetition]},
      {"key": "高中外语语种", "val": [res.student_high_languages]},
      {"key": "高中学习类型", "val": [res.student_high_direction]}
    ];

    topData.value = formatData(topResource)
    middleData.value = formatData(middleResource)
    bottomData.value = formatData(bottomResource)
    url.value = res.url
  })


  return;
  let msg = "有更新条款，请仔细阅读并签署协议"
  // 重新签署协议
  Dialog.alert({
    width: '294rem',
    title: "您签署的协议",
    message: `<div style="color:#000;font-size: 12rem;"><span style="color:#417FF7;">《初升高咨询服务协议》</span> ${msg}</div>`,
    allowHtml: true,
    confirmButtonText: "马上签署",
    confirmButtonColor: "#417FF7"
  })
}
getData();

function locationTo(){
  if(url.value){
    /*router.push({
      path:"/webview",
      query:{
        title:"查看协议详情",
        url:url.value
      }
    })*/
    window.location.href=url.value
  }
}


</script>

<style lang="scss" scoped>
.order-info {
  background-color: #F8F8F8;
  min-height: 100vh;

  .btns {
    padding: 0 30rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30rem;
    --van-button-default-background-color: transparent;
    --van-button-default-border-color: #C7C7C7;
    --van-button-default-color: #7C7C7C;
  }
}

.order {
  margin-bottom: 32rem;

  .top-bg {
    background-color: #417FF7;
    height: 80rem;
    display: flex;
    justify-content: center;
    padding-top: 28rem;

    .bar {
      width: 356rem;
      height: 30rem;
      background-color: #105DF2;
      border-radius: 15rem;

      .line {
        margin: 10rem auto 0;
        width: 342rem;
        border-radius: 7rem;
        height: 14rem;
        background-color: rgba(116, 163, 255, 0.4);

      }
    }
  }

  .content {
    padding: 0 14rem;
    margin-top: -60rem;
    position: relative;
    z-index: 2;

    .header {
      padding-top: 5rem;
      .number {
        font-weight: 600;
      }
    }
    :deep(.t-order-info) {
      padding: 10rem 0;
    }
    .box{
      :deep(.t-order-info) {
        padding: 10rem 17rem;
      }
      :deep(>.t-header){
        padding: 0;
      }
      :deep(>.t-footer){
        padding: 0;
      }
    }

    .footer {
      padding: 5rem 0;
    }
  }
}
</style>
