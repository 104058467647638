import { ref } from 'vue'
import { sleep } from '@/utils/zhuApi'
import { showLoading } from '@/utils/utils'
/* 在给run方法传入一个promise，会在promise执行前或执行后将loading状态设为true，在执行完成后设为false */
/**
 * @description: 请求返回数据与状态
 * @param {Object} params 请求参数
 * @param {Number} delay 自定义延迟参数
 * @return [(requestApi: Record<string, any>) => Promise<any>, Boolean, Boolean]
 */
export const useAutoLoading = ({ delay = 0, loading = false } = {}) => {
  const isLoading = ref(false)
  const loadFail = ref(false)
  const fetch = async requestApi => {
    isLoading.value = true
    loadFail.value = false
    loading && showLoading(true)
    // 测试模拟请求时间
    if (delay) await sleep(delay)
    return new Promise((resolve, reject) => {
      requestApi()
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          loadFail.value = true
          reject(err)
        })
        .finally(() => {
          isLoading.value = false
          loading && showLoading(false)
        })
    })
  }

  return [fetch, isLoading, loadFail]
}
