<template>
  <div class="t-order">
    <div class="t-header">
      <slot name="header"></slot>
    </div>
    <t-img v-if="$slots.header" src="border.png" class="t-border"></t-img>
    <div class="t-order-info">
      <div class="row" v-for="(item,key) in data">
        <div class="key">{{key}}</div>
        <div class="val">{{item}}</div>
      </div>
    </div>
    <t-img v-if="$slots.footer" src="border.png" class="t-border"></t-img>
    <div class="t-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TOrder",
  props:{
    data:Object
  },
  setup(){},
}
</script>

<style lang="scss" scoped>
.t-order{
  border-radius: 10rem;
  overflow: hidden;
  //background-color: white;
  //padding: 0 17rem;
  //border: 1rem dashed #ccc;
  .t-header{
    padding: 0 17rem;
    background-color: white;
  }
  .t-footer{
    background-color: white;
    padding: 0 17rem;
  }
  .t-border{
    display: block;
  }
  .t-order-info{
    background-color: white;
    padding: 12rem 17rem;
    .row{
      display: flex;
      justify-content: space-between;
      font-size: 14rem;
      margin-bottom: 20rem;
      .key{
        width:120rem;
        color: #000;
      }
      .val{
        text-align: right;
      }
    }
    .row:last-child{
      margin-bottom: 0;
    }
  }

}
</style>
