
export const CommonFormProps = {
    bottomLabel:String,
    label:String,
    subTitle:[String,Array], // 子标题
    right:String, // 右侧展示
    type:{
        type:String,
        validator(value){
            return value == null || ["input", "select", "cascader","group","upload","custom","address","show","h2","textarea"].includes(value);
        }
    },
    index:Number,
    modelValue:[String,Number,Object],
    // 最大数量
    maxLength:Number,
    // 在上传模式时有效
    maxSize:Number,
    // 只读属性
    readonly:Boolean,
    // 占位符
    placeholder:String,
    // 其他原生属性
    property:Object,
    // 选项
    options:Object,
    // 当type = select , 且 styleType = cell的时候有效
    cellLabel:String,
    prop:String,
    // select 特有的 样式类型
    styleType:{
        type:String,
        default:'input',
        validator(value){
            return ['input','cell'].includes(value)
        }
    },
    itemStyle:[StyleSheet,String,Object],
    // group特有的属性
    children:Array,
    required:Boolean,
    // 备注信息
    remark:Object,
    error:Boolean,
    source:Object, // 源数据(可以有可无)
}
/**
 * 格式化表单选项数据  , 格式化为 [{text:1,value:2}]的格式
 * @param {Array|Object}data 数据 三种模式: 1. 数组["值"] , 2. 数组包含对象 [{id:1,name:2}] 3. 对象 {1:"数值"}
 * @param {Array<string>} props 可选 ， 当data为数组包含对象的时候有用 ， 数组格式， [key，value]
 */
export function formatOptions(data,props){
    if (Array.isArray(data)){
        let label,value;
        if (props)
            [label,value] = props;
        return data.map(item=>{
            if (props)
                return {
                    text: item[label],
                    value: item[value]
                }
            else
                return {
                    text:item,
                    value:item
                }
        });
    }else if(typeof data === 'object'){
        let opt = [];
        for (let k in data){
            opt.push({
                value:k,
                text:data[k]
            })
        }
        return opt;
    }else{
        throw "[数据格式错误]实际应该为对象或者数组，当前数据格式为： "+typeof data;
    }
}

/**
 * 将数据映射到对应的配置位置中去的val
 * 一般来说只有type为show的需要这个方法
 * @param config  配置数据
 * @param indexes [[0,1]]二维数组 ， 对应config[0][1]
 * @param props ["pros"] 和上面二维数组的数量一一对应
 * @param data 需要设置的数据
 */
export function dataToConfig(config = [] , indexes = [] , props = [] , data = {}){
    props.forEach((item,i)=>{
        let [_0,_1] = indexes[i];
        config[_0][_1].val = data[item];
    })
    return config;
}

/**
 * 通过options查找对应值的项
 * @param value 值
 * @param options 选项
 * @return {*}
 */
export function findValueByOptions(value,options = []){
    for (let item of options){
        if (item.value === value)return item;
    }
}

