<template>
  <div class="bind">
    <t-verify-code logo="bind-01.png" submit-type="3" v-model:phone="phone" v-model:verify="verify" :title="title" button-text="下一步" @onSubmit="onHint"></t-verify-code>
<!--    <Contract class="contract"></Contract>-->
  </div>

</template>

<script setup>
import TVerifyCode from "../../components/VerifyCode/index.vue"
import {useRouter} from "vue-router";
import {Dialog, Toast} from "vant"
import {ref} from "vue";
import {useStore} from "vuex";
import tools from "../../utils/tools.js";
import axios from "../../axios/index.js";
import {formatCacheToAxios, showLoading} from "../../utils/utils.js";

const router = useRouter();
const store = useStore();
let title = `您正在使用的手机号 <span style="font-size: 14rem;">（报名手机号）</span>`
const phone = ref(store.getters.userBaseInfo.mobile),
    verify = ref('');


// 处理缓存中的注册信息数据
function handleCacheData(){
  const registerData = store.getters.registerCache;
  let result = formatCacheToAxios(registerData);;
  // 其他班级
  if (Object.keys(result).indexOf('student_attend_graduation_class_other')>-1){
    if (result['student_attend_graduation_class_other'] != null)
      result['student_attend_graduation_class'] = result['student_attend_graduation_class_other'];
    delete result['student_attend_graduation_class_other'];
  }
  // 简称
  delete result['student_attend_graduation_school_jc'];
  return result;
}
// 提示
function onHint() {
  if (!tools.checkInput('phone',phone.value)){
    return Toast({
      message:"请填写正确的手机号"
    })
  }

  Dialog.confirm({
    title:"用户须知",
    message:"此手机号将作为您报名和联系的唯一手机号，不支持更换手机号操作，请不要随意更换,否则后果自负。",
    confirmButtonText:"更换手机号",
    confirmButtonColor:"#666666",
    cancelButtonColor:"#417FF7",
    cancelButtonText:"我知道了"
  }).then(res=>{
    // 更换手机号

  }).catch(res=>{
    showLoading(true);
    // 我知道了
    store.commit("setRegisterCache",{
      phone:phone.value,
      code:verify.value
    })
    let params = handleCacheData();
    params.goods_pack_code = store.getters.microMessenger ? store.getters.microMessenger.goods_pack_code : undefined;
    if(params.teacher_id === -1)
      delete params.teacher_id;
    axios.post("/api/recruit/family/enter",
        {...params, module: 12}
    ).then(res=>{
      // 跳转合同预览页
      // router.push({path:"/register-contract",query: {...res}});
      router.push({path:"/register-server",query: {id:res.id,phone:res.phone,standard:res.standard}})

    }).catch(err=>{
      if (err.status === 3011){
        Dialog.alert({
          message:err.msg,
          confirmButtonColor:"#417FF7"
        }).then(res=>{
          router.push("/order")
        })
      }
    }).finally(()=>showLoading(false))
  })
}

</script>

<style lang="scss" scoped>
.bind{
  .contract{
    position: absolute;
    top:0;
    left: 0;
    z-index: 2000;
    background: white;
  }
}

</style>
