<template>
<div class="nation">
  <t-card title="常用民族"  v-if="nation != null && Object.keys(nation).length">
    <div style="padding:10rem 0;">
      <van-grid style="border-left: 1px solid #ebedf080">
        <van-grid-item v-for="item in use" :text="item" @click="onSelected(item)"></van-grid-item>
      </van-grid>
    </div>
  </t-card>
  <div class="nation-list-box" v-if="nation != null && Object.keys(nation).length">
    <van-index-bar class="nation-list" :index-list="indexList">
      <template v-for="(item,key) in nation">
        <van-index-anchor :index="key" style="left: 10rem;background-color: transparent;"></van-index-anchor>
        <van-cell v-for="n in item" :title="n" @click="onSelected(n)"></van-cell>
      </template>
    </van-index-bar>
  </div>
  <iframe src="error?type=1" v-else-if="nation != null" class="nation-list-box"></iframe>
</div>
</template>

<script setup>
import axios from "../../axios/index.js";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {showLoading} from "../../utils/utils.js";
import {useRoute, useRouter} from "vue-router";
import tools from "../../utils/tools.js";
const use = ref([]);
const nation = ref(null);
const store = useStore();
const cache = ref(store.getters.nationSource);
const router = useRouter();
const route = useRoute();
const prop = route.query.prop;
const indexList = computed(()=>{
  let res = [];
  if (nation.value!=null)
    for (let key in nation.value){
      res.push(key)
    }
  return res;
})


getData();
// 获取初始数据
function getData(){
  // 当缓存数据不为空 , 且 缓存数据
  if (!tools.isEmpty(cache.value) && Object.keys(cache.value).length > 0)
    setData(cache.value)
  else {
    showLoading(true)
    axios.get("/api/recruit/common/nation").then(res=>{
      setData(res)
      store.commit("setNationSource",res);
      showLoading(false)
    }).catch(err=>{
      showLoading(false);
      nation.value = {};
    })
  }
}
// 设置民族数据
function setData(res){
  use.value = res.use;
  nation.value = res.ordinary;
}
// 选中回调(点击回调)
function onSelected(item) {
  if (prop){
    store.commit("setFormDataCache",{
      [prop]:item
    })
  }else
    store.commit("setRegisterCache",{
      student_nation:item
    })
  router.back();
}
</script>

<style lang="scss" scoped>
.nation{
  padding:  0 14rem;
  .nation-list-box{
    height: 100vh;
  }
  iframe.nation-list-box{
    border: none;
    width: 100%;
    height: calc(100vh - 30px);
  }
}
</style>
