<template>
<div class="server"  v-if="serverType.length">
  <div class="title">请选择服务类型</div>

  <div :class="['type',index===selected?'selected-border':'']" v-for="(item,index) in serverType" @click="onSelectedServer(index)">
    <div class="type-name">类型{{numMap.get(index+1)}}</div>
    <div class="box">
      <div class="server-name">{{ item.standard_name }}</div>
      <div class="rmb">￥{{String(item.goods_price).split(".")[0] || "00"}}.<span class="float">{{String(item.goods_price).split(".")[1]||"00"}}</span></div>
    </div>
    <t-img src="selected.png" class="selected-icon"></t-img>
  </div>
  <div class="button">
    <t-button :disabled="selected === -1" type="primary" @click="submit">确认</t-button>
  </div>

</div>
  <div v-else class="server">
    <t-result
        img="noServer.png"
        hint="当前暂无服务类型，请联系招生老师"
        :hint-style="hintStyle"
        :img-props="imgProps"
    ></t-result>
    <div class="button">
      <t-button type="primary" @click="$router.push('/order')">确认</t-button>
    </div>
  </div>
</template>

<script setup>
  import {ref} from "vue";
  import axios from "../../axios/index.js";
  import {useRoute, useRouter} from "vue-router";
  import TResult from "../../components/Result/index.vue";
  import {showLoading} from "../../utils/utils.js";
  import {useStore} from "vuex";


  let numMap = new Map([[1,"一"], [2,"二"], [3,"三"], [4,"四"], [5,"五"], [6,"六"], [7,"七"], [8,"八"], [9,"九"], [10,"十"]]);
  let route = useRoute()
  let router = useRouter()
  let store = useStore();
  let selected = ref(-1);
  let serverType = ref([]);
  let query = route.query;

  const hintStyle = {
    fontSize:"14rem",
    color:"#3d3d3d",
    marginTop:'29rem',
    lineHeight:"18rem"
  }
  const imgProps = {
    style:{
      width:'210rem',
      height:'210rem'
    }
  }
  function onSelectedServer(index){
    selected.value = index;
  }
  showLoading(true)
  let goods_pack_code = store.getters.microMessenger?store.getters.microMessenger.goods_pack_code:undefined
  axios.get("api/recruit/register/service-type",{
    params:{
      id:query.id,
      goods_pack_code
    }
  }).then(res=>{
    serverType.value = res;
  }).finally(()=>showLoading(false))
  function submit(){
    let standard_id = serverType.value[selected.value].goods_standard_id
    showLoading(true)
    // 不跳转合同预览页
    axios.post("/api/recruit/family/order",{
      phone:query.phone,
      id:query.id,
      standard:standard_id,
      goods_pack_code
    }).then(res=>{
      // 支付拦截
      /*router.push({path:"/order-status",query:{
        status:4
        }})*/
      // 跳转到待支付
      router.push({path:"/wait-pay",query:{
          order_number:res.order_number
        }})
    }).finally(()=>showLoading(false))
  }




</script>

<style lang="scss" scoped>
.server{
  padding:25rem 15rem 70rem;
  box-sizing: border-box;
  background-color: #F7F7F7;
  height:100vh;
  overflow-y: auto;
  position:relative;
  .title{
    color:#333;
    font-size: 15rem;
    line-height: 21rem;
    font-weight:500;
  }
  .type{
    border:1px solid #F7F7F7;
    background-color: #fff;
    border-radius: 10rem;
    overflow: hidden;
    //height:85rem;
    position: relative;
    margin-top: 20rem;
    padding:15rem;
    .type-name{
      font-size: 14rem;
      color: rgba(0,0,0,.6);
      margin-bottom: 12rem;
      line-height: 20rem;
    }
    .box{
      position:relative;
      .server-name{
        font-size: 14rem;
        color:#333;
        line-height: 20rem;
        width:188rem;
        font-weight: 500;
      }
      .rmb{
        font-weight: 700;
        font-size: 18rem;
        color:#333;
        position:absolute;
        bottom:0;
        right:0;
        .float{
          font-weight: 500;
          font-size: 16rem;
        }
      }
    }
    .selected-icon{
      display:none;
      position:absolute;
      right:0;
      bottom:0;
      width:30rem;
      height:25rem;
    }
  }
  .selected-border{
    border:1px solid #3D65FC;
    .selected-icon{
      display:block;
    }
  }
  .button{
    position:fixed;
    bottom:0;
    left : 0;
    width:100%;
    box-sizing: border-box;
    padding: 0 15rem 25rem;
    background-color:#F7F7F7;
  }
}
</style>
