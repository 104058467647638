<template>
  <div class="teacher-select">
    <div class="teacher-list-box">
      <van-index-bar class="teacher-list" v-if="teachers!=null && Object.keys(teachers).length">
        <template v-for="(t,ZM) in teachers">
          <van-index-anchor :index="ZM" style="left: 10rem;background-color: transparent;"></van-index-anchor>
          <van-cell v-for="teacher in t" :title="teacher.name+'('+teacher.mobile+')'" @click="onSelected({text:teacher.name,value:teacher.id})"></van-cell>
        </template>
      </van-index-bar>
      <iframe src="error?type=1" class="teacher-list" v-else/>
    </div>
  </div>

</template>

<script setup>
import axios from "../../axios";
import {ref} from "vue";
import constants from "../../utils/constants.js";
import tools from "../../utils/tools.js";
import {showLoading} from "../../utils/utils.js";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
const active = ref(0);
const school_data = ref({});
const store = useStore();
const router = useRouter();
const route = useRoute();
const school_id = route.query.school_id || store.getters.userBaseInfo.consulting_school;
const teachers = ref(null);
showLoading(true);
function onSelected(val) {
  if (route.query.school_id)
    store.commit("setSignupCache",{
      entity_teacher:val
    });
  else
    store.commit("setRegisterCache",{
      teacher_id:val
    })
  router.back();
}
axios.get("/api/recruit/register/teacher").then(res=>{
  school_data.value = res;
  console.log(res)
  if (school_id != null){
    for (let  i = 0; i<res.length;i++) {
      let item = res[i];
      if (item.entity_school === Number(school_id)){
        active.value = i;
        teachers.value = res[i].teacher;
        break;
      }
    }
  }
  showLoading(false);

}).catch(res=>{
  showLoading(false);
  school_data.value = [];
})
</script>

<style lang="scss" scoped>
.teacher-list-box{
  height: calc(100vh - 44px);
  position: relative;
  overflow: hidden;
}
iframe.teacher-list{
  border: none;
  width: 100%;
  height: 100%;
}
.teacher-list{
  max-height: calc(100vh - 44px);
  overflow: scroll;
  :deep(.van-index-bar__sidebar){
    position: absolute;
  }
  :deep(.van-index-anchor--sticky){
    position: absolute;
    left:0 !important;
    transform:translate3d(0px, 0, 0px) !important;
  }
}
:deep(.van-tabs__line){
  background-color: transparent;
  background-image: url("https://main.pro.honghueducation.com:8003/bf-static/pre-register/tab_line.png");
  background-repeat: no-repeat;
  height: 10px;
  width: 30rem;
  background-size: 100% 100%;
}
</style>
