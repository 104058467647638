export default {
  loading: false, // 是否显示加载中
  loadingText: null, // 加载中文本
  progress: 0, // 进度条
  signupCache: null, // 注册缓存
  registerCache: null, // 签约缓存
  baseConfig: null, // 公用基础配置数据缓存
  userBaseInfo: null, // 用户注册信息
  nationSource: null, // 民族数据源
  addressSource: null, // 省数据源
  registerInstance: null, // 签约签约咨询页面的实例
  formDataCache: {}, // 表单数据缓存
  formConfigCache: null, // 表单配置缓存
  formExtraCache: null, // 表单配置额外数据缓存
  idCard: null, // 报名学生身份证号
  keepList: [], // 缓存页name
  microMessenger: null, // 微信程序信息
  pathInfo:{ // 根据不同的登录类型匹配不同的路径
    13:{
      login:"/2024-online/login",
      order:"/2024-online/order",
    }

  },
}
