<template>
  <div class="t-cascader">
    <div class="t-shown">
      <van-field :placeholder="placeholder" :modelValue="modelVal" readonly @click="onClick" :label="label" :label-width="labelWidth" v-bind="fieldProps">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
    </div>
    <van-popup v-model:show="picker" position="bottom" @click.stop>
      <van-cascader
          v-model="cascaderValue"
          :title="title"
          :options="options"
          v-bind="cascaderProps"
          @close="$emit('cancel',$event);picker = false;"
          @finish="onConfirm"
          @change="$emit('change',$event)"
      />
    </van-popup>
  </div>
</template>

<script>
import {ref,computed} from "vue";

export default {
  name:"TCascader",
  emits:["update:modelValue","change","confirm","cancel","onClick"],
  props:{
    modelValue:[String,Object,Number],
    // 只读属性, 不会触发popup
    readonly:Boolean,
    title:String,
    placeholder:String,
    options:Object,
    label:String,
    labelWidth:String,
    // vant 官方picker 属性
    cascaderProps:Object,
    // vant field的属性
    fieldProps:Object
  },
  computed:{
    modelVal(){
      if(Array.isArray(this.modelValue))
        return this.modelValue.map(item=>item.text).join("-");
      else return this.modelValue;
    }
  },
  watch:{
    modelValue(n){
    }
  },
  setup(){
    let picker = ref(false)
    let cascaderValue = ref()
    return {
      picker,
      cascaderValue,
    }
  },
  methods:{
    onConfirm(e){
      this.$emit("update:modelValue",e.selectedOptions);
      this.$emit("confirm",e.selectedOptions);
      this.picker = false;
    },
    onClick(){
      if (!this.readonly)
        this.picker = true;

      this.$emit("onClick");
    }
  }
}
</script>

<style lang="scss" scoped>
.t-cascader{
  --van-cell-background-color:#eee;
  .t-cell{
    border-radius: 100rem;
  }
}
</style>
