<template>
  <div class="status">
    <t-result :img="img" :hint="text" :imgProps="{
      style:'width:200rem;height:160rem'
    }">
      <div class="btns" v-if="status != null">
        <t-button type="primary" @click="handle">{{btnText}}</t-button>
        <div v-if="status === 1" class="cancel" @click="cancel">我再想想</div>
      </div>
    </t-result>

  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import { ref} from "vue";
import {Toast} from "vant";
import {useStore} from "vuex";
import {onHistoryBack} from "../../utils/recordRouter.js";
const router = useRouter();
const route = useRoute();
const text = ref("");
const img = ref("");
const btnText = ref("");
let status = route.query.status != null ? parseInt(route.query.status):null;
const store = useStore()
store.commit("setSignupCache",null);
const onHistory = onHistoryBack((self)=>{
  console.log("禁止返回上一页注册")
},false);
switch (status){
  case 1:
    text.value = "是否参加初升高咨询服务？";
    img.value = ('signup-03.png');
    btnText.value = "是";
    break;
  case 0:
    text.value = "将会有服务顾问与您联系，请稍等";
    img.value = ('signup-05.png');
    btnText.value = "我已了解";
    break;
  case 2:
    text.value = "将会有服务顾问与您联系，请稍等";
    img.value = ('signup-04.png');
    btnText.value = "我已了解";
    break;
}
function handle() {
  if (status === 1){
    router.push("/register")
  }else if(status === 2){
    Toast({
      message:"咨询老师将会马上联系您，请稍等",
      duration:3000
    })
  }
}
function cancel(){
  if (status === 1){
    router.push("/order")
  }
}
</script>

<style lang="scss" scoped>
.status{
  .btns{
    .cancel{
      margin-top: 18rem;
      font-size: 14rem;
      color:#787878;
    }
  }
}
</style>
