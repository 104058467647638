// import Login from "../pre-pages/Login/index.vue"
import Login from "../pages/2024-online/login.vue"
import BPN from "../pre-pages/BindPhoneNumber/index.vue"
import SignUp from "../pre-pages/SignUp/index.vue"
import SignUpNext from "../pre-pages/SignUp/next.vue"
import SignUpStatus from "../pre-pages/SignUp/status.vue"
import Order from "../pre-pages/Order/index.vue"
import OrderInfo from "../pre-pages/Order/info.vue"
import OrderStatus from "../pre-pages/Order/status.vue"
import OrderWait from "../pre-pages/Order/status-wait.vue"
import OrderOfficial from "../pre-pages/Order/official.vue"
import Register from "../pre-pages/Register/index.vue"
import RegisterPhone from "../pre-pages/Register/phone.vue"
import Contract from "../pre-pages/Register/contract.vue"
import RegisterSuccessful from "../pre-pages/Register/successful.vue"
import WaitPay from "../pre-pages/Pay/wait-pay.vue"
import TeacherList from "../pre-pages/List/teacher.vue"
import NationList from "../pre-pages/List/nation.vue"
import AddressList from "../pre-pages/List/address.vue"
import SchoolList from "../pre-pages/List/school.vue"
// import SchoolList from "../pages/2024-online/school-of-graduation/list.vue"
import Webview from "../pre-pages/Webview/index.vue"
import TeacherListBackup from "../pre-pages/List/teacher-backup.vue"
import RegisterServer from "../pre-pages/Register/server.vue"
import TeacherListBackup2 from "../pre-pages/List/teacher-backup-2.vue"
import Home from "../pre-pages/Home/index.vue"
export default [
    {
        path:"/login",
        name:"login",
        meta:{
            title: "登录"
        },
        component:Login
    },
    {
        path:"/bind-phone-number",
        name:"BPN",
        meta:{
            onHistory: true
        },
        component:BPN
    },
    {
        path:"/signup",
        name:"signup",
        meta:{
            keepAlive:true,
            title:"注册信息填写"
        },
        component:SignUp
    },
    {
        path:"/signup-next",
        name:"signupNext",
        meta:{
            title:"注册信息填写"
        },
        component:SignUpNext
    },
    {
        path:"/signup-status",
        name:"signupStatus",
        meta:{
            title:"注册信息填写",
            onHistory:true
        },
        component:SignUpStatus
    },
    {
        path:"/order",
        name:"order",
        meta:{
            title:"我的订单",
            onHistory:true,
            pullRefresh:true
        },
        component:Order
    },
    {
        path:"/order-info",
        name:"orderInfo",
        meta:{
            title:"我的订单"
        },
        component:OrderInfo
    },
    {
        path:"/pay-info",
        name:"orderPay",
        meta:{
            title:"待支付订单"
        },
        component: () => import('../pre-pages/Order/pay-info.vue')
    },
    {
        path:"/order-status",
        name:"orderStatus",
        meta:{
        },
        component:OrderStatus
    },
    {
        path:"/order-wait",
        name:"orderWait",
        meta:{
        },
        component:OrderWait
    },
    {
        path:"/order-official",
        name:"orderOfficial",
        meta:{
        },
        component:OrderOfficial
    },
    {
        path:"/register",
        name:"register",
        meta:{
            title:"初升高咨询服务报名",
            keepAlive:true
        },
        component:Register
    },
    {
        path:"/register-phone",
        name:"registerPhone",
        meta:{
            title:"初升高咨询服务报名",
        },
        component:RegisterPhone
    },
    {
      path:"/register-server",
      name:"registerServer",
      meta:{
          title:"初升高咨询服务报名"
      }  ,
        component:RegisterServer
    },
    {
        path:"/register-contract",
        name:"registerContract",
        meta:{
            title:"初升高咨询服务报名",
            onHistory: true
        },
        component:Contract
    },
    {
        path:"/register-successful",
        name:"registerSuccessful",
        meta:{
            title:"初升高咨询服务报名",
        },
        component:RegisterSuccessful
    },
    {
        path:"/wait-pay",
        name:"waitPay",
        meta:{
            title:"待支付订单",
            onHistory: true
        },
        component:WaitPay,
        // redirect:"/order-status?status=4"
    },
    {
        path:"/teacher-list",
        name:"teacherList",
        meta:{
            title:"选择与您联系的咨询老师",
        },
        component:TeacherList
    },
    {
        path:"/nation-list",
        name:"nationList",
        meta:{
            title:"选择学生的民族",
        },
        component:NationList
    },
    {
        path:"/address-list",
        name:"addressList",
        meta:{
            title:"选择学生的民族",
        },
        component:AddressList
    },
    {
        path:"/school-list",
        name:"schoolList",
        meta:{
            title:"选择初中就读学校",
        },
        component:SchoolList
    },
    {
        path:"/webview",
        name:"webview",
        component:Webview
    },
    {
        path:"/register-teacher-list",
        name:"registerTeacherList",
        component:TeacherListBackup
    },
    {
        path:"/register-teacher-list-2",
        name:"registerTeacherList2",
        component:TeacherListBackup2
    },
]
// 渲染一个组件或者一个空组件
export function renderComponent(component) {
    if (component)
        return component
    else return {
        render: (e) => e("router-view")
    }
}
