import { setLocal, getLocal } from '@/utils/zhuApi'

const loginInfo = {
  10: { name: '高一预报名', path: '/order', login: '/login' },
  11: { name: '高一正式报名', path: '/zb/Order/index', login: '/formal/login/index' },
  12: { name: '高一直接报名', path: '/zb/Order/index', login: '/formal/login/login' },
  20: { name: '高二高三续费', path: '/zb/Order/binding', login: '/formal/login/login' },
  31: { name: '高三复读生正式报名', path: '/zb/Order/repeat', login: '/formal/login/grade3' },
  40: { name: '补录订单', path: '/zb/Order/offline', login: '/formal/login/grade3' },
}

/**
 * 获取登录状态信息
 * @returns object
 */
export const getLoginData = () => {
  const data = getLocal('LOGIN_INFO')
  return data || {}
}

/**
 * 保存登录类型数据
 * @param {*} loginType 登录类型
 * {
 * 10.高一预报名  11.高一正式报名  12.高一直接报名
 * 20.高二高三续费
 * 30.待定中  31.高三复读生正式报名
 * 40.入学信息登记系统->补录订单
 * }
 * @param {*} data 自定义保存数据
 */
export const onLoginChange = (loginType, data = {}) => {
  const item = loginInfo[loginType] || {}
  const newData = { loginType, name: item.name, path: item.path, login: item.login, ...data }
  setLocal('LOGIN_INFO', newData)
}

