<template>
<div class="t-safe-page">
  <slot></slot>
  <div class="t-safe-space">

  </div>
</div>
</template>

<script>
export default {
  name: "t-safe-page"
}
</script>

<style scoped lang="scss">
.t-safe-page{
  box-sizing: border-box;
  min-height: 100vh;
  .t-safe-space{
    height: 30rem;
  }
}
</style>
