import getters from "./getters.js";
import state from "./state.js";
import mutations from "./mutations.js";
import {createStore} from "vuex";

const store = createStore({
    state,
    mutations,
    getters
})
export default store;
