<template>
  <div class="teacher-select">
    <van-tabs swipeable v-model:active="active" shrink>
      <van-tab v-for="(item, index) in school_data">
        <template #title
          ><span :style="index === active ? { color: '#417FF7', fontWeight: 'bold' } : { color: '#868686' }">{{ item.entity_school_name }}</span></template
        >
        <div class="teacher-list-box" v-if="item.entity_school !== -1">
          <van-index-bar class="teacher-list" v-if="Object.keys(item.teacher).length">
            <van-cell v-show="(!!prop && !exclude.includes(prop)) && !notShowNoTeacher" title="未与任何招生老师沟通" @click="onSelected({ text: '未与任何招生老师沟通', value: 0 })"></van-cell>
            <template v-for="(t, ZM) in item.teacher">
              <van-index-anchor :index="ZM" style="left: 10rem; background-color: transparent"></van-index-anchor>
              <van-cell v-for="teacher in t" :title="teacher.name + '(' + teacher.mobile + ')'" @click="onSelected({ text: teacher.name, value: teacher.id })"></van-cell>
            </template>
          </van-index-bar>
          <iframe src="error?type=1" class="teacher-list" v-else />
        </div>
        <div v-else>
          <div style="width: 100%;border-top: 1px solid #F5F5F7;padding: 20rem 40rem 30rem;box-sizing: border-box;display: flex;flex-flow: column;height: calc(100vh - 60rem)">
            <div style="flex: 1">
              <t-input style="margin-bottom: 10rem;" v-model="schoolName" placeholder="请输入" label="咨询老师所在校区"></t-input>
              <t-input style="margin-bottom: 10rem;" v-model="teacherName"  placeholder="请输入" label="咨询老师姓名"></t-input>
            </div>
            <t-button style="margin-top: 22rem;" type="primary" :disabled="tools.isEmpty([schoolName, teacherName],'||')" @click="onOtherSubmit">确认</t-button>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup>
import axios from '../../axios'
import { ref } from 'vue'
import constants from '../../utils/constants.js'
import tools from '../../utils/tools.js'
import { showLoading } from '../../utils/utils.js'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Dialog } from 'vant'
const active = ref(0)
const school_data = ref({})
const exclude = ref(['belong_teacher_id'])
const store = useStore()
const router = useRouter()
const route = useRoute()
const school_id = route.query.value
const prop = route.query.prop
const notShowNoTeacher = route.query.notShowNoTeacher;
const schoolName = ref('') // 其他 - 校区
const teacherName = ref('') // 其他 - 老师姓名
showLoading(true)
function onSelected(val) {
  if (!prop) {
    store.commit('setRegisterCache', {
      teacher_id: val
    })
    router.back()
  } else {
    if (val.value === 0)
      Dialog.confirm({
        title: '再次确认',
        message: '没有招生老师与您沟通介绍并指导入学报名吗？',
        confirmButtonText: '点错了',
        confirmButtonColor: 'rgba(0, 0, 0, 0.80)',
        cancelButtonText: '没有',
        cancelButtonColor: 'rgba(65, 127, 247, 1)'
      }).catch(err => {
        store.commit('setFormDataCache', {
          [prop]: val
        })
        router.back()
      })
    else {
      store.commit('setFormDataCache', {
        [prop]: val
      })
      router.back()
    }
  }
}
axios
  .get('/api/recruit/register/teacher')
  .then(res => {
    res.push({entity_school: -1, entity_school_name: "其他",})
    school_data.value = res;
    if (school_id != null) {
      for (let i = 0; i < res.length; i++) {
        let item = res[i]
        if (item.consulting_school === Number(school_id)) {
          active.value = i
          break
        }
      }
    }
    showLoading(false)
  })
  .catch(res => {
    showLoading(false)
  })


function onOtherSubmit(){
  store.commit('setFormDataCache', {
    [prop]: {
      text:teacherName.value,
      school:schoolName.value,
      value:-1,
    }
  });
  router.back();
}
</script>

<style lang="scss" scoped>
.teacher-list-box {
  height: calc(100vh - 44px);
  position: relative;
  overflow: hidden;
}
iframe.teacher-list {
  border: none;
  width: 100%;
  height: 100%;
}
.teacher-list {
  max-height: calc(100vh - 44px);
  overflow: scroll;
  :deep(.van-index-bar__sidebar) {
    position: absolute;
  }
  :deep(.van-index-anchor--sticky) {
    position: absolute;
    left: 0 !important;
    transform: translate3d(0px, 0, 0px) !important;
  }
  --van-cell-vertical-padding: 20px;
}
:deep(.van-tabs__line) {
  background-color: transparent;
  background-image: url('https://main.pro.honghueducation.com:8003/bf-static/pre-register/tab_line.png');
  background-repeat: no-repeat;
  height: 10px;
  width: 30rem;
  background-size: 100% 100%;
}
</style>
