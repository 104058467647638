<template>
<div class="t-swipe-card" ref="swipeCard" :style="'height:'+boxWidth+'px'">
  <div class="t-swipe-card-piece" :style="'transform:translateY('+(stackHeight)+'px)'" @touchmove.prevent>
    <slot></slot>
  </div>

</div>
</template>

<script>

export default {
  name:"TSwipeCard",
  provide(){
    return {
      root:this
    }
  },
  props:{
    stackHeight:{
      type:Number,
      default:30
    },
    width: {
      type:String,
      default:"100%"
    },
    height:{
      type:String,
      default:"100%"
    },
    current:Number,
    vertical:Boolean
  },
  emits:["update:current"],
  data(){
    return {
      children:[],
      stack:0,
      touch:{
        start:{},
        move:{},
        end:{}
      },
      boxWidth:0
    }
  },
  watch:{
    current(val){
      this.computedZIndex();
    }
  },
  mounted(){
    this.init();
    this.computedZIndex();
    this.boxWidth = this.children[0].$el.offsetHeight + this.stackHeight;
  },
  methods:{
    /**
     * 格式化transform
     * @param el
     * @return {{}}
     */
    formatTransform(el){
      let transform = el.style.transform;
      let attrs = transform.split(" ");
      let res = {};
      attrs.forEach(item=>{
        let kReg = /^[\w_]+/;
        let vReg = /\([\d\w-]+\)/;
        let key = item.match(kReg);
        let val = item.match(vReg);
        res[key[0]] = val[0].replace("(","").replace(")","");
      })
      return res;
    },
    /**
     * transform对象转字符串
     * @param transform
     * @return {string}
     */
    transformToString(transform={}){
      let res = "";
      for (let k in transform){
        res += `${k}(${transform[k]}) `
      }
      return res;
    },
    touchmove(e){
      let touch = e.touches[0];
      let el = this.children[this.current].$el;
      let transform = JSON.parse(JSON.stringify(this.touch.start.transform));
      // 纵向
      if (this.vertical){
        let distance = this.touch.start.clientY - touch.clientY;
        let ty = transform.translateY.replace("px","").toNumber();
        transform.translateY = (ty-distance)+"px";
      }else{
        // 横向
        let distance = this.touch.start.clientX - touch.clientX;
        let ty = transform.translateX.replace("px","").toNumber();
        transform.translateX = (ty-distance)+"px";
      }
      el.style.transform = this.transformToString(transform);
    },
    touchstart(e){
      if (e.touches.length>1 || this.children.length<2)return;
      this.touch.start = e.touches[0];
      this.touch.start.transform = this.formatTransform(this.children[this.current].$el);
      this.$refs.swipeCard.addEventListener('touchmove',this.touchmove,this);
      this.$refs.swipeCard.addEventListener('touchend',this.touchend,this)
    },
    touchend(e){
      this.$refs.swipeCard.removeEventListener('touchmove',this.touchmove,this);
      let i = this.current;
      let el = this.children[i].$el;
      let touch = e.changedTouches[0];
      let transform = this.touch.start.transform;
      const len = this.children.length;
      const setCur = ()=>{
        let cur = this.current === this.children.length-1 ? 0 : this.current+1;
        this.$emit("update:current",cur);
      }
      // 纵向
      if (this.vertical){
        // 滑动距离
        let distance = Math.abs(this.touch.start.clientY - touch.clientY);
        let height = el.offsetHeight;
        // 如果滑动距离小于卡片高度的1/3，则恢复原状
        if (this.children.length === 1 || distance < height/3){
          transform.translateY = this.stack*(len - this.children[i].zIndex - 1)+'px';
          el.style.transform = this.transformToString(transform);
        }else{
          setCur();
        }
      }else{
        let distance = Math.abs(this.touch.start.clientX - touch.clientX);
        const width = el.offsetWidth;
        // 如果滑动距离小于卡片宽度的1/3，则恢复原状
        if (this.children.length === 1 || distance < width / 3){
          transform.translateX = "0px";
          el.style.transform = this.transformToString(transform);
        }else{
          setCur();
        }
      }
      // if (distance > )
       this.$tools.addClassName(el,'transition');
       setTimeout(()=>{
         this.$tools.removeClassName(el,'transition');
       },200)
      // 恢复初始数据
      this.touch = {
        start:{},
        move:{},
        end:{}
      }
      this.$refs.swipeCard.removeEventListener('touchend',this.touchend,this);
    },
    init(){
      let len = this.children.length;
      this.children.forEach((item,index)=>{
        item.index = index;
        item.len = len;
      });
      this.$refs.swipeCard.addEventListener('touchstart',this.touchstart,this);
      this.stack = this.stackHeight / (this.children.length - 1);
    },
    // 计算zIndex
    computedZIndex(){
      let len = this.children.length;
      this.children.forEach((item)=>{
        let index = item.index;
        let zi = len-1;
        if (index > this.current){
          zi = len - (index-this.current)-1;
        }else if(index < this.current){
          zi = this.current-index-1;
        }
        item.zIndex = zi;
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.t-swipe-card{
  position: relative;
  user-select: none;
  width:100%;
  z-index:10;
  perspective: 1000px;
  .t-swipe-card-piece{
    transform-style: preserve-3d;
    perspective-origin: 50% 50%;
    width:100%;
    height:100%;

  }
}
</style>
