<template>
  <v-button :block="block" :size="size" v-bind="$attrs" @click="onClick">
    <template v-for="(item,name) in $slots">
      <slot v-if="name === 'default'"></slot>
      <slot v-else :name="name"></slot>
    </template>
  </v-button>
</template>

<script>
import {Button} from "vant";
import tools from "../../utils/tools.js";
import {showLoading} from "../../utils/utils.js";
import axios from "../../axios/index.js";

export default {
  name: "TButton",
  emits:['click'],
  props:{
    submit:Boolean,
    block: {
      type:Boolean,
      default:true
    },
    size:{
      type:String,
      default:'large'
    }
  },
  components:{
    VButton:Button
  },
  methods:{
    onClick:tools.debounce(function(e){this.$emit("click",e);if (this.submit)showLoading(true)},false,200)
  },
  mounted() {
    if (this.submit) {
      axios.interceptors.response.use((res) => {
        showLoading(false);
        return Promise.resolve(res);
      }, err => {
        showLoading(false)
        return Promise.reject(err);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.van-button.van-button--primary{
  background: linear-gradient( 90deg, #68C0FF 0%, #3D7CF6 100%) !important;
}
.van-button--primary,.van-button--danger{
  border:none !important;
}
.van-button.van-button--disabled{
  background: #ccc !important;
  border:1px solid #ccc !important;
  opacity: 1 !important;
}

</style>
