<template>
  <div class="t-new-order radius-10">
    <div class="content">
      <van-cell class="cell" title-class="cell-title" value-class="cell-value" title="订单状态"><span :style="{color:statusMap[status].color||'#ccc'}">{{statusMap[status].title}}</span></van-cell>
      <van-cell class="cell" title-class="cell-title" value-class="cell-value" v-for="(item,key) in data" :title="key" :key="key">{{item}}</van-cell>
    </div>
    <div class="footer" v-if="!$slots.footer && showFooter">
      <span class="cancel" v-if="showCancel" @click="$emit('cancel')">{{ cancelText }}</span>
      <span v-else></span>
      <van-button :type="confirmType" :plain="confirmPlain" style="height:32rem;width: 84rem;" v-if="showConfirm" size="small" @click="$emit('confirm')">{{ confirmText }}</van-button>
    </div>
    <div class="footer" v-else-if="$slots.footer && showFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
import {ref,onMounted,reactive,computed,watch,defineEmits,defineProps} from 'vue'
const props = defineProps({
  status:[Number,String],
  statusMap: {
    type:Object,
    default(){
      return {
        0:{
          title:"待支付",
          color:"#F53F3F"
        },
        1:{
          title:"已支付",
          color:"#3491FA"
        },
        203:{
          title:"已取消",
          color:"#9A9A9A"
        },
        201:{
          title:"已支付",
          color:"#3491FA"
        },
        4:{
          title:"待审核",
          color:"#F09D12"
        },
        202:{
          title:"待签约",
          color:"#4B34FA"
        }
      }
    }
  },
  data:Object,
  showCancel:Boolean,
  showConfirm:Boolean,
  showFooter:Boolean,
  confirmPlain:Boolean,
  confirmType:{
    type:String,
    default:"primary"
  },
  cancelText:{
    type:String,
    default:"取消订单"
  },
  confirmText:{
    type:String,
    default:"去签约"
  }
})
</script>

<script>
  export default {
    name:"TNewOrder"
  }
</script>

<style lang="scss" scoped>
.t-new-order{
  background-color: white;
  .content{
    padding: 10rem 0;
    .cell::after{
      border: none !important;
      height: 0;
      line-height: 0;
    }
    .cell{
      :deep(.cell-title){
        color: black;
        font-size: 14rem;
        width:100rem;
        flex:none;
      }
      :deep(.cell-value){
        color: black;
        font-size: 14rem;
        flex:1;
      }
    }
  }
  .footer{
    display: flex;
    justify-content: space-between;
    padding: 20rem;
    border-top: 1px solid #f2f2f2;
    align-items: center;
  }
}
</style>
