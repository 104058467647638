export default {
  token: 'TOKEN',
  traceId: 'TRACE_ID',
  signup: 'SIGNUP', // 注册页面选择框的信息
  signupCache: 'SIGNUP_CACHE', // 注册页面的缓存
  registerCache: 'REGISTER_CACHE', // 注册页面的缓存
  nationSource: 'NATION_SOURCE', // 民族数据源
  addressSource: 'ADDRESS_SOURCE', // 地区数据源
  baseConfig: 'BASE_CONFIG', // 基础数据缓存
  userBaseInfo: 'USER_BASE_INFO', // 用户注册信息
  allAddressCache: 'ALL_ADDRESS_CACHE', // 全国地址缓存
  openId: 'OPEN_ID', // openId缓存
  formDataCache: 'FORM_DATA_CACHE', // 表单数据缓存
  formConfigCache: 'FORM_CONFIG_CACHE', // 表单配置缓存
  formExtraCache: 'FORM_EXTRA_CACHE',// 表单额外数据缓存
  idCard: 'ID_CARD', // 身份证
  // 登录模式
  // 0 预报名 ,
  // 1 直接报名 ,
  // 2 高二续费 ,
  // 3 高三续费 ,
  // 4 正式报名 ,
  // 5 高三复读报名(微信登录),
  // 6 高三咨询服务(微信登录)
  // 7 入学信息登记系统(线下补录)(微信登录)
  // 10 高二复读报名(手机登录)
  // 11 高三咨询服务(手机登录)
  // 12 入学信息登记系统(线下补录)(手机登录)
  // 13 2024线上报名
  loginType: 'LOGIN_TYPE',
  keepList:"KEEP_LIST",
  microMessenger:"MICRO_MESSENGER", // 微信相关的信息
  pathInfo:"PATH_INFO", // 登录路径
}
export let routerMap = {
  dev_local: '',
  dev: 'dev_fe_zhaosheng',
  test: 'test_fe_zhaosheng',
  pre: 'pre_fe_zhaosheng',
  prod: 'pro_fe_zhaosheng'
}
