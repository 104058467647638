<template>
  <div class="wait-pay">
    <div class="top radius-10">
      <div class="money">
        <div style="color: #666;font-size: 14rem;">待支付金额</div>
        <div class="price">
          <span class="num">{{tools.formatMoney(price/100)}}</span>
          <span style="color:#666;font-size: 16rem;">元</span>
        </div>
      </div>
    </div>
    <div class="content">
      <t-order class="order-card radius-10" v-for="(item,index) in data" :data="item" :key="'order-card-'+index"></t-order>
      <div class="wait-box" v-if="offline">
      <img class="img my-1" src="@/assets/imgs/zp-14.png" alt="等待" />
      <div class="text">财务正在飞速审核中，请耐心等待~~</div>
    </div>
      <slot></slot>
    </div>
    <div class="footer" v-if="!$slots.footer">
      <van-button :class="{cancel:true,cancelOnly:!showPay}" v-if="showCancel" type="primary" size="large" plain @click="cancelOrder">取消订单</van-button>
      <view class="ml-2" style="flex:1;" v-if="showPay">
        <t-button class="pay" type="primary" @click="onPay">选择支付方式</t-button>
      </view>
    </div>
    <div class="footer" v-else>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
import tools from "../../utils/tools.js"
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {dialog,Dialog} from "../Dialog/index.js";

const route = useRoute();
const router = useRouter();
const store = useStore();
const props = defineProps({
  // 金额
  price:{
    type:[Number,String],
    default:0
  },
  // 展示立即支付按钮?
  showPay:{
    type:Boolean,
    default:true
  },
  // 展示取消按钮?
  showCancel:{
    type:Boolean,
    default:true
  },
  cancelDialog:{
    type:Dialog
  },
  offline:Boolean,
  data: {
    type:Array,
    required:true
  }
})
const emits = defineEmits(["pay","cancel"])



function  onPay() {
  emits('pay');
}

function cancelOrder(){

  if (props.cancelDialog)props.cancelDialog.show()
  else
    dialog.confirm({
      message:"群定取消订单？",
      cancelText:"点错了",
      layout:"right",
      onConfirm(close){
        emits("cancel");
        close();
      },
    })

}
</script>



<script>
export default {
  name:"TPageWaitPay"
}
</script>
<style lang="scss" scoped>
.wait-pay{
  .red{
    color:#F74156;
  }
  $p:10px;
  position: relative;
  .top{
    padding: 15rem 20rem;
    background: white;
    .money{
      .price{
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .num{
          line-height: 40rem;
          color:#222;
          font-size: 34rem;
          font-weight: 600;
        }
      }
    }
  }
  .content{
    margin-top: 28rem;
    .order-card{
      margin-bottom: 12rem;
      padding-top: 10rem;
      padding-bottom: 10rem;
      background: white;

    }
  }
  .footer{
    margin-top: 175rem;
    height: 60rem;
    display: flex;
    justify-content: flex-end;
    .cancel{
      width: 105rem;
    }
    .cancelOnly{
      flex:1;
    }
    .pay{
      width:100%;
    }
  }
  .pay-type{
    padding: 10rem 0 25rem;
    text-align: center;
    .row:last-child{
      border-bottom: none  ;
    }
    .row{
      padding: 25rem 10rem;
      border-bottom: 1px solid #eee;
      font-size: 16rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon{
        font-size: 20rem;
        margin-right: 10rem;
      }
      .icon-img{
        width: 26rem;
        height: 20rem;
        margin-right: 10rem;
      }
    }
  }
}
.wait-box {
  text-align: center;
  .img {
    width: 24rem;
    height: 24rem;
  }
  .text {
    color: #333;
  }
}
</style>
