<!--注册信息填写-->
<template>
  <t-safe-page class="signup" :style="{backgroundImage:'url('+src+'signup-01.png)'}">
    <van-form style="padding-top: 10rem">
      <t-input style="margin-bottom: 10rem;" v-model="name" placeholder="请输入" @change="onChange" :props="props" label="您的姓名"></t-input>
      <t-input style="margin-bottom: 10rem;" v-model="relation" select :options="opt" @change="onChange($event,'r_name')" placeholder="请选择" label="您与学生的关系"></t-input>
      <t-input style="margin-bottom: 10rem;" v-model="r_name" placeholder="请输入" @change="onChange" :props="props" label="请输入您与学生的关系" v-if="parseInt(relation?relation.value:'')===3"></t-input>
      <t-input style="margin-bottom: 10rem;" v-model="s_name" placeholder="请输入" @change="onChange" :props="props" label="学生姓名"></t-input>
      <t-input style="margin-bottom: 10rem;" v-model="addr" select :options="opt1" @change="onChange" placeholder="请选择" label="您想咨询的高中"></t-input>
<!--      <t-input style="margin-bottom: 10rem;" v-model="ts" select :options="tsOpt" @change="onChange($event,'ts')" placeholder="请选择" label="咨询老师所在校区"></t-input>-->
      <t-input style="margin-bottom: 10rem;" v-model="t_name" placeholder="请选择" select readonly :props="{readonly:true}" label="咨询老师姓名" @click="selectTeacher"></t-input>
      <t-button style="margin-top: 22rem;" type="primary" @click="next" :disabled="disabled">下一步</t-button>
    </van-form>


  </t-safe-page>
</template>

<script setup>
import { computed, getCurrentInstance, nextTick, onActivated, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router"
import { useStore } from "vuex";
import tools from "../../utils/tools.js";
import axios from "../../axios/index.js";
import constants from "../../utils/constants.js";
import { formatSelect, setCache, showLoading } from "../../utils/utils.js";
import { Toast } from "vant";
import TSafePage from "../../components/SafePage/index.vue";

const store = useStore();
const router = useRouter();
const { proxy } = getCurrentInstance();
const src = proxy.$resource;
const props = ref({
  maxlength: 30,
})
// 教师校区详细数据
const teachers = ref(null);
// 用户姓名
const name = ref("");
// 关系
const relation = ref("");
// 学生姓名
const s_name = ref("");
// 关系名称
const r_name = ref("");
// 地址
const addr = ref("");
// 教师所在校区
const ts = ref(null);
// 教师姓名
const t_name = computed(() => {
  return store.getters.signupCache ? store.getters.signupCache.entity_teacher : undefined
});
// 与学生的关系
const opt = ref([]);
// 咨询学校
const opt1 = ref([]);
// 教师所在校区
const tsOpt = computed(()=>{
  if (!teachers.value)return [];
  let res = teachers.value.map((item)=>{
    return {
      value:item.entity_school,
      text:item.entity_school_name
    }
  })
  return res;
})

const disabled = computed(() => {
  const n = name.value;
  const r = relation.value;
  const rn = r_name.value;
  const s = s_name.value;
  const a = addr.value;
  const t = t_name.value;
  if (r && parseInt(r.value) === 3)
    return tools.isEmpty([n, r, s, a, rn, t], "||");
  else
    return tools.isEmpty([n, r, s, a, t], "||");
  // return tools.isEmpty([n,r,s,a], "||");
});

function next(e) {
  let reg = /^([\u4e00-\u9fa5]|[a-zA-Z·.])+$/;
  if (!reg.test(name.value)) {
    return Toast({
      message: "您的姓名包含非法字符"
    })
  } else if (!reg.test(s_name.value)) {
    return Toast({
      message: "学生姓名包含非法字符"
    })
  }
  setSignupCache();
  router.push("/signup-next")
}

//
function selectTeacher() {
  // if (ts.value)
    router.push({
      path: '/register-teacher-list-2',
      query: {
        prop: "entity_teacher",
        commitName:"setSignupCache"
      }
    })
  // else
  //   Toast({
  //     message: "请选择咨询老师所在校区"
  //   })
}

function getCache() {
  const infoCache = store.getters.signupCache;
  if (infoCache) {
    // name.value = infoCache.name;
    // relation.value = infoCache.relationship;
    // r_name.value = infoCache.relationship_name;
    // s_name.value = infoCache.student_name;
    // addr.value = infoCache.consulting_school;
    // ts.value = infoCache.teacher_school;
    // t_name.value = infoCache.entity_teacher;

    store.commit('setSignupCache', {
      entity_teacher: ""
    })
  } else {
    name.value = "";
    relation.value = "";
    r_name.value = "";
    s_name.value = "";
    addr.value = "";
    t_name.value = "";
    ts.value = "";
  }
}

onActivated(() => {
  // getCache();
})

function init() {
  const result = tools.getSession(constants.signup);
  getCache();
  // 教师所在校区
  axios.get("/api/recruit/register/teacher").then(res=>{
    teachers.value = res;
  })
  if (!!result) {
    // 与学生的关系
    opt.value = formatSelect(result["relationship_data"], 'value', 'relationship');
    // 学校
    opt1.value = formatSelect(result["consulting_school_data"], "consulting_school_name", "consulting_school");

  } else {
    showLoading(true);
    axios.post("/api/recruit/register/screen").then(res => {
      tools.setSession(constants.signup, res);
      showLoading(false)
      // 与学生的关系
      opt.value = formatSelect(res["relationship_data"], 'value', 'relationship');
      // 学校
      opt1.value = formatSelect(res["consulting_school_data"], "consulting_school_name", "consulting_school");
    }).catch(err => {
      showLoading(false)
    })

  }

}

onMounted(() => {
  init();
  bgResize();
  // document.querySelector(".signup").style.height = h +'px';
})

function bgResize() {
  let dom = document.querySelector(".signup");
  let h = dom.clientHeight > window.innerHeight ? dom.clientHeight : window.innerHeight;
  dom.style.backgroundSize = "100% " + h + 'px';
}

function setSignupCache() {
  const r = relation.value ? relation.value.value : null;
  const relationMap = {
    1: "母亲",
    2: "父亲",
    3: ""
  }
  setCache(constants.signupCache, {
    name: name.value,
    relationship: relation.value,
    ...(parseInt(r) === 3 ? {
      relationship_name: r_name.value,
    } : {
      relationship_name: relationMap[r]
    }),
    student_name: s_name.value,
    consulting_school: addr.value,
    entity_teacher: t_name.value,
    entity_school: ts.value
  })
}

watch(ts, (n, o) => {
  if (o != null && n.value !== o.value) {
    store.commit('setSignupCache', {
      entity_teacher: ""
    })
  }
})

function onChange(e, prop) {
  // setSignupCache();
  if (prop === "r_name") {
    nextTick(() => {
      bgResize();
    })
  } else if (prop === "ts") {

  }
}
</script>

<style lang="scss" scoped>
.signup {
  background-size: 100% 100%;
  padding: 126rem 40rem 30rem;
  background-repeat: no-repeat;
  position: relative;
}
</style>
