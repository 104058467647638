<template>
  <van-overlay :show="show" class="t-modal" @click="this.$emit('update:show',false)">
    <div class="t-modal-box" :style="{width}" @click="(e)=>e.stopPropagation()">
      <div class="t-modal-title">
        <span v-if="!$slots.title">{{title}}</span>
        <slot name="title"></slot>
      </div>
      <div class="t-modal-content">
        <slot></slot>
      </div>
      <div class="t-modal-footer">
        <slot name="footer"></slot>
        <div class="t-btn-group" v-if="!$slots.footer">
          <van-button class="t-btn" type="primary" block @click="(e)=>{this.$emit('confirm',$event);this.$emit('update:show',false)}">确认</van-button>
          <van-button class="t-btn" type="default" block @click="(e)=>{this.$emit('cancel',$event);this.$emit('update:show',false)}">取消</van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "TModal",
  props:{
    show:Boolean,
    title:String,
    width:{
      type:String,
      default(){
        return "80%"
      }
    }
  },
  emits:['update:show',"confirm",'cancel'],
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.t-modal{
  display: flex;
  align-items: center;
  justify-content: center;
  .t-modal-box{
    background-color: white;
    border-radius: 10rem;
    .t-modal-title{
      padding: 10rem 24rem;
      text-align: center;
    }
    .t-modal-footer{
      .t-btn-group{
        display: flex;
      }
    }
  }
}
</style>
