<template>
  <div class="contract">
    <div class="content">
<!--      <iframe v-if="!!src" :src="src" class="iframe"></iframe>-->
      <pdf :pdfUrl="url" class="iframe" ></pdf>
    </div>
    <div class="btns">
      <t-button type="primary" @click="sure">{{confirmText}}</t-button>
      <div class="btn-cancel" v-if="!type" @click="cancel">{{ cancelText }}</div>
      <a class="btn-cancel" :href="src" :download="cid+'.docx'" v-if="type" @click="cancel">{{ cancelText }}</a>
    </div>
  </div>
</template>

<script setup>
import pdf from "../../components/PDF/index.vue";
import {Dialog} from "vant";
import {useRouter, useRoute} from "vue-router";
import {ref} from "vue";
import axios from "../../axios/index.js";
import {formatCacheToAxios} from "../../utils/utils.js";
import contract from "./config/contract.js";
import {useStore} from "vuex";
import {onHistoryBack} from "../../utils/recordRouter.js";
const router = useRouter();
const route = useRoute();
const store = useStore();
const type = route.query.type;
const confirmText = ref(!type?"我已认真阅读并同意合同内容":"关闭");
const cancelText = ref(!type?"我再想想":"下载电子版");
const id = route.query.id; // id
const phone = route.query.phone; // 手机号
const cid = route.query.contract; // 合同编号
const standard = route.query.standard; // 套餐
const url = route.query.url; // 当type=1时,合同地址
const src = ref(null); // iframe 地址
const env = import.meta.env.MODE;
if (type){
  src.value = url;
}else if (["test","dev"].includes(env)){
  src.value = contract[0]['cid']?contract[0]['cid']['name']:""
}else {
  src.value = contract[1]['cid']?contract[1]['cid']['name']:"";
}
if (!type)
  onHistoryBack((e)=>{
  },false)
store.commit("setRegisterCache",null);

function sure() {
  if (!type)
    Dialog.confirm({
      message:"所有内容我已经知晓，开始支付费用",
      confirmButtonText:"我再想想",
      confirmButtonColor:"#666",
      cancelButtonText:"开始支付",
      cancelButtonColor:"#417FF7"
    }).catch(res=>{
      axios.post("/api/recruit/family/order",{
        phone,
        id,
        standard
      }).then(res=>{
        // 支付拦截
        /*router.push({path:"/order-status",query:{
          status:4
          }})*/
        // 跳转到待支付
        router.push({path:"/wait-pay",query:{
          order_number:res.order_number
          }})
      })
    })
  else{
    router.back();
  }
}
function cancel() {
  if (type){

  }else{
    router.replace("/order")
  }
}
</script>

<style lang="scss" scoped>
.contract{
  min-height: calc(100vh - 48rem);
  padding: 24rem 0;
  position: relative;
  .content{
    height: calc(100vh - 98rem - 66rem);
    overflow-y: auto;
    padding-bottom: 12rem;
    .iframe{
      width: 100%;
      border: none;
      height: 100%;
    }
  }
  .btns{
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 148rem);
    background-color: white;
    padding: 18rem 74rem 30rem;
    text-align: center;
    border-top: 1px solid #eee;
    height: 80rem;
    --van-button-normal-font-size:14rem;
    .btn-cancel:hover{
      color: black;
    }
    .btn-cancel:link{
      color: black;
    }
    .btn-cancel:active{
      color: black;
    }
    .btn-cancel{
      display: inline-block;
      margin-top: 10rem;
      text-decoration: #787878;
      color:#787878;
    }
  }
}
</style>
