import Button from './Overwrite/Button.vue'
import Modal from './Modal/index.vue'
import Icon from './Icon/index.vue'
import Input from './Input/index.vue'
import Select from './Select/index.vue'
import Result from './Result/index.vue'
import Image from './Image/index.vue'
import Order from './Order/index.vue'
import NewOrder from './Order/new.vue'
import Loading from './Loading/index.vue'
import Card from './Card/index.vue'
import SafePage from './SafePage/index.vue'
import Cascader from './Cascader/index.vue'
import SwipeCard from "./SwipeCard/index.vue"
import Popup from "./Popup/popup.vue"
import Dialog from "./Dialog/dialog.vue";
import Address from "./Address/index.vue";
import PagePay from "./Page/pay.vue"
import PageWaitPay from "./Page/wait-pay.vue"
import * as vant from "vant"

// 挂在型函数
import popup from "./Popup/index.js"
import dialog from "./Dialog/index.js"
const components = {Icon, Modal, Button, Input, Select, Result, Image, Order, NewOrder, Loading, Card, SafePage, Cascader,SwipeCard,Popup,Dialog,Address,PagePay,PageWaitPay}
export function globalComponents(app) {
  components.forEach(item => {
    app.component(item.name, item)
  })
}
export default {
  install(vue){
    vue.config.globalProperties.$popup = popup;
    vue.config.globalProperties.$dialog = dialog;
    vue.config.globalProperties.$components = components;
    vue.config.globalProperties.$vant = vant;
    Object.keys(components).forEach(item => {
      let com = components[item];
      vue.component(com.name, com)
    })
  }
}
