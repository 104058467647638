<template>
<van-overlay class="t-popup" :show="show" @click="$emit('clickOverlay')">
  <div :class="['t-popup-content',sideIn?'t-popup-sideIn':'']" @click.stop>
    <slot></slot>
  </div>
</van-overlay>
</template>

<script>
export default {
  name: "TPopup",
  emits:['clickOverlay'],
  props:{
    show:Boolean,

  },
  data(){
    return{
      sideIn:false
    }
  },
  mounted() {
    setTimeout(()=>this.sideIn = true,0)
  }
}
</script>

<style lang="scss" scoped>
.t-popup{

  .t-popup-content{
    transition: 200ms ease-in-out all;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 20rem 20rem 0 0;
    background-color: white;
    padding:32rem 0 44rem 0;
    width: 100%;
    transform: translateY(100vh);
  }
  .t-popup-sideIn{
    transform: translateY(0);
  }
}
</style>
