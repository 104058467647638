import vuex from '../store/index.js'
import axios from '../axios/index.js'
import { showLoading } from './utils.js'
import {orderPay,localGet,localSet} from "./zhuApi.js"
import store from "../store/index.js";
import router from "../router/index.js"
import {useAutoLoading} from "../hooks/useAutoLoading.js";
let token = vuex.getters.token

/**
 * 验证身份证号码
 * @param {Object} data {idCard,name,type} type 1家长 ,2 学生
 * @return {*|Promise<AxiosResponse<any>>}
 */
export function checkIdCard(data) {
  return axios.post('/api/recruit-v2/direct-registration/idcard-verify', data)
}
// 验证一下 是否参加过报名
export function checkIsApplication(student_idcard) {
  return axios.get('/api/recruit-v2/reread/is-application', { params: { student_idcard } })
}
// 获取学校列表
export function getSchoolList() {
  return axios.get('/api/recruit/common/consulting-school')
}
// 获取用户注册信息
export function getUserInfo() {
  return axios.get('/api/recruit/register/get-register')
}
// 获取全国省市区
export function getAddressInfo() {
  return axios.get('/api/recruit/common/city')
}
// 异步获取省市区
export function asyncGetAddressInfo(code = '000000') {
  return axios.get('/api/recruit/common/area', {
    params: {
      address_code: code
    }
  })
}
// 获取线下报名学生信息
export function getOfflineStudentInfo(idCard){
  return axios.get("/api/recruit-v2/offline-supplement/binding-student",{
    params:{
      student_idcard:idCard
    }
  });
}
// 获取基础配置
/**
 * 获取基础配置
 * @return {Promise<AxiosResponse<any>>}
 * road    Object
 * 就读途径枚举
 * repetition    Object
 * 是否留级枚举
 * suspend    Object
 * 是否休学枚举
 * year    Array
 * 毕业年份
 * foreign_language    Object
 * 外语语种
 * direction    Object
 * 学习方向
 * direction1    Array
 * 学习方向(1.2版本)
 * school    Object
 * 期望报名学校
 * payment_method    Object
 * 支付方式
 * middle_class    Array
 * 初三毕业班级
 */
export function getBaseConfig() {
  let goods_pack_code = store.getters.microMessenger?store.getters.microMessenger.goods_pack_code:undefined;
  return axios.get('/api/recruit/common/basic',{
    params:{
      goods_pack_code
    }
  })
}

// 获取考试地点
export let getExamAddress = (function () {
  let address = null
  return function () {
    return new Promise((resolve, reject) => {
      if (address) resolve(address)
      return axios
        .get('/api/recruit-v2/common/examination-area')
        .then(res => {
          let str = JSON.stringify(res)
          str = str.replaceAll('address_code', 'value')
          str = str.replaceAll('address_name', 'text')
          str = str.replaceAll(',"children":[]', '')
          address = JSON.parse(str)
          resolve(address)
        })
        .catch(e => reject(e))
    })
  }
  // 获取考试地点
})()
// 上传
export function uploadFile(file, data = {}) {
  let formData = new FormData()
  let _data = Object.assign(
    {},
    {
      type: 3
    },
    data
  )
  formData.append('upload', file)
  for (let k in _data) {
    formData.append(k, _data[k])
  }
  return axios.post(import.meta.env.API_BASE_URL_2 + '/api/site/upload', formData, {
    timeout: 0
  })
}

/**
 * 删除OSS文件
 * @param filename
 * @param type
 * @return {Promise<AxiosResponse<any>>}
 */
export function deleteFile(filename, type = 1) {
  return axios.post(
    import.meta.env.API_BASE_URL_2 + '/api/upload/del-file',
    {
      type,
      filename
    },
    {
      timeout: 0
    }
  )
}

/**
 * 下载文件
 * @param filename
 * @param type
 * @return {Promise<AxiosResponse<any>>}
 */
export function downloadFile(filename, type = 1) {
  return axios.get(import.meta.env.API_BASE_URL_2 + '/api/upload/download', {
    params: {
      filename,
      type
    },
    timeout: 0
  })
}
/*
{
    "order": "202462015091812073_1",
    "payer": 1,
    "payment_method": "WXPay",
    "stages_id": "",
    "order_number": "202462015091812073_1"
}
{
    "type": 3,
    "show": false,
    "order": "202462015091812073_1",
    "payer": 1,
    "payment_method": "WXPay",
    "stages_id": "",
    "list": []
}
==================
{
    "order": "202462015091812073_1",
    "payer": 1,
    "payment_method": "WXPay",
    "stages_id": "",
    "order_number": "202462015091812073_1"
}
{
    "type": 3,
    "show": false,
    "order": "202462015091812073_1",
    "payer": 1,
    "payment_method": "WXPay",
    "stages_id": "",
    "list": []
}
*/
/**
 * 默认的订单支付逻辑
 * @param data
 * @param {{}} state { order,payment_method,payer }
 */
export function defaultOrderPay(data,state){
  const [fetch] = useAutoLoading({ loading: true });
  return fetch(() => orderPay(data)).then((res) => {
    const { order_m, url, code, is_qr, order_type, id } = res;
    if (code === 2) {
      return router.push("/zb/Status/order?type=" + order_type + "&order=" + state.order + "&id=" + id+"&stages=2&complete_status=1&order_m="+order_m);
    }
    if (is_qr === 1) {
      localSet("CODE_METHOD", state.payment_method);
      localSet("CODE_PAYER", state.payer);
      return router.push("/zb/Payment/code?order=" + state.order + "&order_m=" + order_m + "&url=" + url);
    }
    router.push("/zb/Payment/wait?order=" + state.order + "&order_m=" + order_m + "&url=" + url);
  });
}
