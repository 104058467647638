<template>
<div>

</div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

let route = useRoute();
let router = useRouter();
let store = useStore();
let goods_pack_code = route.query.goods_pack_code;

if (store.getters.microMessenger.flag){
  store.commit("setMicroMessenger", {goods_pack_code});
  router.replace("/login")

}else{
  // 不是微信浏览器
  // 通过扫码进入
  if (goods_pack_code != null){
    router.replace({
      path:"/error",
      query:{
        type:4,
        width:139,
        height:139
      }
    })
  }
}
</script>

<style scoped>

</style>
