<template>
  <div class="t-card">
    <div class="t-card-title" v-if="!!title">
      {{title}}
    </div>
    <slot name="title"></slot>
    <slot></slot>
  </div>
</template>

<script >
export default {
  name: "t-card",
  props: {
    title:String
  }
}
</script>

<style scoped lang="scss">
.t-card{
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(230, 238, 255, 0.5);
  border-radius: 20px;
  padding: 10rem 20rem;
  .t-card-title{
    color: #333333;
    font-size:  16rem;
    font-weight: 500;
  }
}
</style>
