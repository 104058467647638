<template>
  <div>
    <verify-code v-model:phone='phone'
                 v-model:verify='verify'
                 :button-text='buttonText'
                 @onSubmit='submit'
                 logo='bind-01.png'
                 :title='titleText'
    ></verify-code>
  </div>
</template>

<script setup>
import VerifyCode from "../../components/VerifyCode/index.vue";
import {useRoute, useRouter} from "vue-router";
import {getCurrentInstance, ref} from "vue";
import axios from "../../axios/index.js";
import constants from "../../utils/constants.js";
import tools from "../../utils/tools.js";
import {changeRouterKey, clearCache, showLoading} from "../../utils/utils.js";
import {onHistoryBack} from "../../utils/recordRouter.js";
import {onLoginChange} from "@/hooks/useLoginData";

const { proxy } = getCurrentInstance();
const src = proxy.$resource;
const route = useRoute();
const router = useRouter();
const data = ref({})
let phone = ref("");
let verify = ref("");
let type = route.query.type && Number(route.query.type);
const buttonText = ref("绑定手机号");
const titleText = ref("请绑定你正在使用的手机号");
const openId = route.query.wechat_openid;
const wechat_number = route.query.wechat_number;
const gender = route.query.gender;
if (type === 2) {
  buttonText.value = "登录";
  tools.setWxTitle("登录");
  titleText.value = "欢迎使用鸿鹄高中教育集团初升高咨询服务系统"
  clearCache(); // 清除缓存 (bug100066)
} else {
  const onHistory = onHistoryBack((self)=>{
    console.log("禁止返回上一页注册")
  },false);
}

function submit() {
  changeRouterKey(router,'signup');
  showLoading(true)
  let login = (data)=>{
    onLoginChange(10)
    tools.setSession(constants.token,data.access_token);
    if (data.register_status === 1){
      const rs = data.recruit_status;
      if (rs === 0)router.push("/signup-status?status=2");
      else
      router.push("/order")
    }
    else router.push("/signup")
  }
  // 手机号登录
  if (type === 2) {
    axios.post('api/recruit/auth/mobile-login', {mobile: phone.value, mobile_code: verify.value, module: 11}).then(result => {
      login(result);
      showLoading(false);
    }).catch(err=>showLoading(false))
    // 绑定手机号
  } else {
    data.value.mobile = phone.value;
    data.value.mobile_code = verify.value;
    data.value.wechat_openid = openId;
    data.value.wechat_number = wechat_number;
    data.value.gender = gender;
    axios.post('api/recruit/auth/weChat-binding', data.value).then(result => {
      login(result);
      showLoading(false);
    }).catch(err=>showLoading(false))
  }
  // 如果不是首次注册
  // router.push("/order")
  // 如果是首次注册
  // router.push("/signup")
}

</script>
<script>
export default {

}
</script>

<style lang='scss' scoped>

</style>
