<template>
  <div class="login">
    <div class="logo">
      <img src="../../assets/imgs/hxj-logo.png" alt="logo" />
      <div class="text">
        <div class="zh">鸿鹄高中教育集团</div>
        <div class="py">HONGHU HIGH  SCHOOL Education  Group</div>
      </div>
    </div>
    <div class="info">
      <div class="title">缴费系统</div>
<!--      <van-field placeholder="手机号"></van-field>-->
      <verify-code
        v-model:phone="form.phone"
        v-model:verify="form.verify"
        button-text="登录"
        :disabled="disabled"
        @onSubmit='submit'
      >
        <template #protocol>

          <div class='read'>
            <van-config-provider :theme-vars='checkboxTheme'>
              <van-checkbox class='accept' v-model='form.accept'>
            <span class='xy'>
              阅读并同意
                  <span class='jump' @click='onShowContract($event,0)'>《用户服务协议》</span>和<span class='jump' @click='onShowContract($event,1)'>《用户隐私协议》</span>
            </span>
              </van-checkbox>
            </van-config-provider>
          </div>
        </template>
      </verify-code>
    </div>
    <!--  用户协议详情  -->
    <van-action-sheet v-model:show='showContract' :title='contractTitle' >
      <div class='contractContent'>
        <iframe :src="contract" class="iframe"></iframe>
        <div class='accept'><t-button block round type='primary' @click='onAccept'>我已认真阅读并同意</t-button></div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script setup>
import VerifyCode from "../../components/VerifyCode/index.vue";
import {reactive, computed, ref, getCurrentInstance, onMounted, onBeforeMount} from "vue";
import {changeRouterKey, clearCache, setLoginType, showLoading} from "../../utils/utils.js";
import tools from "../../utils/tools.js";
import constants from "../../utils/constants.js";
import axios from "../../axios/index.js";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {onHistoryBack} from "../../utils/recordRouter.js";
import {Toast} from "vant";

const onHistory = onHistoryBack((self)=>{
  console.log("禁止返回上一页")
},false);
const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const src = proxy.$resource;
const checkboxTheme = {
  checkboxBorderColor: "#000"
};
clearCache()
// 是否展示用户协议
const showContract = ref(false);
// 用户协议标题
const contractTitle = ref("用户服务协议");
// 协议内容
const contract = ref(``)
const form = reactive({
  phone:null,
  verify:null,
  accept:false
})
const _config={
  route:{
    meta:{
      title:"登录",
      onHistory:true
    }
  }
}
const disabled = computed(()=>{
  return !form.verify || !form.phone || !form.accept
})
const loginType = location.pathname.indexOf("/login") === 0?0:13;
/**
 * @desc 当点击协议或者隐私的时候 显示协议
 * @param e event
 * @param type 0:用户协议  1:隐私条款
 */
function onShowContract(e, type) {
  e.stopPropagation();
  showContract.value = true;
  if (type === 0) {
    contractTitle.value = "用户服务协议";
    contract.value = import.meta.env.BASE_OSS_URL+"contract/login.html";
  }else{
    contractTitle.value = "用户隐私协议";
    contract.value = import.meta.env.BASE_OSS_URL+"contract/privacy-policy.html"
  }
}

/**
 * @desc 阅读并同意协议
 */
function onAccept() {
  showContract.value = false;
  form.accept = true;
  showLoading(false);
}
onMounted(()=>{
  setLoginType(loginType);
})

function submit() {
  changeRouterKey(router,'signup');
  showLoading(true)
  let login = (data)=>{
    // 预报名
    if (loginType === 0){
      if (data.register_status === 1){
        const rs = data.recruit_status;
        if (rs === 0)router.push("/signup-status?status=2");
        else
          router.push("/order")
      }
      else router.push("/signup")
      // 正式报名
    }else{
      if (data.register_status === 1){
        const rs = data.recruit_status;
        if (rs === 0)router.push("./signup-status?status=2");
        else
          router.push("/2024-online/home")
      } else router.push("/2024-online/parent-register")
    }

    tools.setSession(constants.token,data.access_token);
    store.commit("setUserBaseInfo",data.userInfo);
    showLoading(false);
  }
  axios.post('api/recruit/auth/mobile-login', {mobile: form.phone, mobile_code: form.verify, module: 11}).then(login).catch(err=>showLoading(false))
}
</script>

<style lang="scss" scoped>
.login{
  background: linear-gradient(170deg,#72C0FB 5%, #AEEBFF 15%,#E9FEFF 40%,#F2F1FF 80%);
  height:100vh;
  padding:16rem;
  box-sizing: border-box;
  .logo{
    display: flex;
    align-items: center;
    padding-top: 24rem;
    img{
      width: 60rem;
      height:60rem;
      margin-right: 6rem;
    }
    .zh{
      font-size: 20rem;
      color:#232323;
      line-height: 23rem;
      font-weight: 600;
    }
    .py{
      font-size: 8rem;
      color: #343434;
      font-weight: 500;
    }
  }
  .info{
    background-color: white;
    border-radius: 14rem;
    padding: 20rem 20rem 80rem;
    margin-top: 30rem;

    :deep(.t-verify){
      padding:0;
    }
    :deep(.t-verify .verify){
      margin-bottom: 0;
    }
    .title{
      font-size: 20rem;
      text-align: center;
      margin-bottom: 36rem;
    }
    .read {
      font-size: 12rem;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 33rem;
      .xy {
        margin-left: 5rem;
        font-size: 12rem;
        color: #000;

        .jump {
          color: #417FF7;
        }
      }
    }
  }
  --van-action-sheet-close-icon-color: black;
  --van-action-sheet-close-icon-size: 18rem;
  .contractContent {

    .iframe {
      padding: 0 24rem;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 12rem;
      color: #000000;
      font-weight: 400;
      width:calc(100% - 48rem);
      height:50vh;
      border:none;
    }

    .accept {
      width: 228rem;
      margin: 32rem auto 32rem;
    }
  }
}
</style>
