<template>
  <iframe :src="src" class="iframe"></iframe>
</template>

<script setup>
import {useRoute} from "vue-router";
import tools from "../../utils/tools.js";
const route = useRoute();
const src = route.query.url;
const title = route.query.title;
tools.setWxTitle(title);


</script>

<style lang="scss" scoped>
.iframe{
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
}
</style>
