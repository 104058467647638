<template>
  <div class="t-loading">
    <van-overlay :show="!!show" class-name="mask">
      <van-loading  color="#999"></van-loading>
      <div class="t-loading-text" v-text="$store.state.loadingText"></div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "t-loading",
  props:{
    show:[Boolean,Number]
  },
}
</script>

<style lang="scss" scoped>
.t-loading{
  --van-overlay-background-color:rgba(255,255,255,.8);
  position: relative;
  z-index: 9999;
  &-text{
    margin-top: 20rem;
    font-size: 14rem;
    color:#666
  }
}
.mask{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
