<!--注册信息填写-->
<template>
<t-safe-page class="signup" :style="{backgroundImage:'url('+src+'signup-02.png)',paddingTop:sh(126)+'rem'}">
  <t-input style="margin-bottom: 10rem;" v-model="form.physiology" select :options="opt" placeholder="请选择" label="学生重大生理疾病"></t-input>
  <t-input style="margin-bottom: 10rem;" v-model="form.psychology" select :options="opt1" placeholder="请选择" label="学生情绪或心理健康问题"></t-input>
  <t-input style="margin-bottom: 10rem;" v-model="form.crime" select :options="opt2" placeholder="请选择" label="学生是否有犯罪前科"></t-input>
  <t-button style="margin-top: 32rem;" type="primary" :disabled="disabled" submit @click="finished">注册完毕</t-button>
</t-safe-page>
</template>

<script setup>

import {computed, getCurrentInstance, onMounted, ref} from "vue";
import tools from "../../utils/tools.js";
import constants from "../../utils/constants.js";
import {formatCacheToAxios, formatSelect, setCache, sh, showLoading} from "../../utils/utils.js";
import {useStore} from "vuex";
import axios from "../../axios/index.js";
import {Toast} from "vant";
import {useRouter} from "vue-router";
import TSafePage from "../../components/SafePage/index.vue";
const store = useStore();
const {proxy} = getCurrentInstance();
const src = proxy.$resource;
const router = useRouter();
const data = tools.getSession(constants.signup);
const opt = ref(formatSelect(data["physiology_data"],"physiology_value","physiology"));
const opt1 = ref(formatSelect(data["psychology_data"],"psychology_value","psychology"));
const opt2 = ref(formatSelect(data["crime_data"],"crime_value","crime"));
const form = ref({
  physiology:opt.value[0], // 重大疾病
  psychology:opt1.value[0], // 心理问题
  crime:opt2.value[0], // 犯罪前科
})
const cache = store.getters.signupCache;
const disabled = computed(()=>{
  const s = form.value.physiology;
  const p = form.value.psychology;
  const c = form.value.crime;
  return tools.isEmpty([s,p,c], "||");
})




function finished(){
  // 设置缓存
  // showLoading(true)
  let result = setCache(constants.signupCache,form.value)
  const params = formatCacheToAxios(result);
  if(params.entity_teacher === -1){
    delete params.entity_teacher
  }
  axios.post("/api/recruit/register/register",params).then(res=>{
    let status = res.recruit_status === 0?2:1;
    // showLoading(false);
    router.push({path:'/signup-status',query:{status}})
  })
}
onMounted(()=>{
  document.querySelector(".signup").style.height = window.innerHeight+'px';
})
</script>

<style lang="scss" scoped>
.signup{
  background-size: 100% 100%;
  padding: 0 40rem 60rem;
  position: relative;
}
</style>
