<script>
export default {
  name: 'ZFlexPage',
  props: {
    footerCss: {
      type: Object,
      default: () => ({})
    },
    footerBgColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="flex-page-wrapper">
    <div class="flex-page-main">
      <slot></slot>
    </div>
    <div class="flex-page-footer" v-if="$slots.footer" :style="{ backgroundColor: footerBgColor, ...footerCss }">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flex-page {
  &-wrapper {
    height: 100vh;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }
  &-main {
    overflow-y: auto;
  }
  &-footer {
    flex-shrink: 0;
    padding: 12rem 17.5rem;
  }
}
</style>
