import { Toast } from 'vant'
import axios from '@/axios'
import { useRouter as useRouters, useRoute as useRoutes } from 'vue-router'

/**
 * @description 提示信息
 * @param {Object} config 提示配置
 * @return undefined
 */
export const toast = config => {
  let [message, position, other] = ['请输入提示', 'top', {}]
  if (typeof config === 'string') {
    message = config
  } else {
    const { message: val1, position: val2, ...data } = config
    val1 && (message = val1)
    val2 && (position = val2)
    data && (other = data)
  }
  Toast({ message, position, ...other })
}
/**
 * @description 加载提示
 * @param {String} message 提示信息
 * @param {String} type 加载图标类型  circular spinner
 * @return undefined
 */
toast.loading = (message = '加载中...', type = 'circular') => {
  Toast.loading({
    message,
    loadingType: type,
    forbidClick: true,
    duration: 0
  })
}
/**
 * @description 关闭提示
 * @return undefined
 */
toast.clear = () => {
  Toast.clear()
}
/**
 * @description 判断数据类型
 * @return String
 */
export const typeOf = function (target) {
  return Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
}
/**
 * @description 模拟延时
 * @param {Number} delay 需要延时的时长
 * @returns {String}
 */
export const sleep = delay => new Promise(resolve => setTimeout(resolve, delay))
/**
 * @description 获取oss地址
 * @return String
 */
export const getOss = () => {
  return import.meta.env.BASE_OSS_URL
}
/**
 * @description 使用路由
 * @return Object
 */
export const useRouter = () => {
  const router = useRouters()
  return router
}
/**
 * @description 获取路由参数
 * @return Object
 */
export const useRoute = () => {
  const route = useRoutes()
  return route
}

/**
 * @description 获取url参数
 * @param {String} key 参数名
 * @return String|Null
 */
export const getQueryString = key => {
  let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substring(1).match(reg)
  return r ? decodeURIComponent(r[2]) : r
}

/**
 * @description 合并参数
 * @param {String} obj 数据对象
 * @return String|Null
 */
export const joinQueryString = (obj = {}) => {
  let param = ''
  Object.keys(obj).forEach(key => {
    param += `&${key}=${obj[key]}`
  })
  return param.replace('&', '?')
}
/**
 * @description 数字添加人民币
 * @param {String} val 传入的值
 * @return String
 */
export const addRMB = val => {
  if ([undefined, ''].includes(val)) return ''
  if (isNaN(Number(val))) return val
  return '￥' + val
}

// 禁止网页返回上一页
export const stopBack = () => {
  history.pushState({}, '', document.URL)
}
// 关闭整个浏览器
export const closePage = () => {
  document.addEventListener(
    'WeixinJSBridgeReady',
    function () {
      WeixinJSBridge.call('closeWindow')
    },
    false
  )
  WeixinJSBridge.call('closeWindow')
}

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return String
 */
export function localGet(key) {
  const value = localStorage.getItem(key)
  try {
    const val = JSON.parse(value)
    if (typeof val === 'number') {
      return value
    }
    return val
  } catch (error) {
    return value
  }
}

/**
 * @description 存储localStorage
 * @param {String} key Storage名称
 * @param {Any} value Storage值
 * @return void
 */
export function localSet(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * @description 清除localStorage
 * @param {String} key Storage名称
 * @return void
 */
export function localRemove(key) {
  localStorage.removeItem(key)
}

/**
 * @description 清除所有localStorage
 * @return void
 */
export function localClear() {
  localStorage.clear()
}

/**
 * @desc 设置localStorage
 * @param {String} key
 * @param {Any} value
 * @return void
 */
export function setLocal(key, value) {
  let sessionKeys = getLocal('SESSION_KEYS') || []
  if (value == null) {
    let index = sessionKeys.indexOf(key)
    if (index > -1) sessionKeys.splice(index, 1)
    localStorage.removeItem(key)
  } else if (typeof value === 'object') value = JSON.stringify(value)
  if (!sessionKeys.includes(key) && value != null) {
    sessionKeys.push(key)
  }
  if (value != null) localStorage.setItem(key, value)
  localStorage.setItem('SESSION_KEYS', JSON.stringify(sessionKeys))
}

/**
 * @desc 获取localStorage的内容
 * @param {String} key
 * @return any
 */
export function getLocal(key) {
  let value = localStorage.getItem(key)
  try {
    const val = JSON.parse(value)
    if (typeof val === 'number') {
      return value
    }
    return val
  } catch (e) {
    return value
  }
}

/**
 * @desc 设置微信标题
 * @param {String} text 标题
 * @return
 */
export function setWxTitle(text) {
  document.title = text
  let dom = document.createElement('iframe')
  dom.style.display = 'none'
  dom.onload = function () {
    setTimeout(function () {
      dom.remove()
    }, 10)
  }
  document.body.appendChild(dom)
}

const validate = (val, type = 0, obj) => {
  if (!val || !obj[type]) {
    return false
  }
  if (obj[type].test(val)) {
    return true
  }
  return false
}

/**
 * @desc 验证中文、姓名
 * @param {String} val 待验证值
 * @return Boolean
 */
export function validateChinese(val, type = 0) {
  // 0.中文
  // 1.汉族姓名
  // 2.包含少数民族姓名 取消掉了名字个数的限制，并且支持中间加“·”的姓名的判断
  // 3.包含英文姓名 加入了适用空格的判断
  const obj = {
    0: /^[\u4E00-\u9FA5]$/,
    1: /^[\u4E00-\u9FA5]{2,4}$/,
    2: /^[\u4E00-\u9FA5]+(·[\u4E00-\u9FA5]+)*$/,
    3: /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/
  }
  return validate(val, type, obj)
}

/**
 * @desc 验证手机号码
 * @param {String} val 待验证值
 * @return Boolean
 */
export function validatePhone(val, type = 0) {
  // 0.手机号码
  // 1.座机号码
  // 2.严格手机号码
  const obj = {
    0: /^1\d{10}$/,
    1: /^(0\d{2,3})-?(\d{7,8})$/,
    2: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
  }
  return validate(val, type, obj)
}

/**
 * @desc 验证手机验证码
 * @param {String} val 待验证值
 * @return Boolean
 */
export function validateCode(val, type = 0) {
  // 0.4-6位验证码
  const obj = {
    0: /^\d{4}$/
  }
  return validate(val, type, obj)
}
/**
 * @desc 验证15位和18位身份证号码
 * @param {String} val 待验证值
 * @return Boolean
 */
export function validateIdCard(idCard, type = 0) {
  // 0.普通验证
  // 1.严格验证
  // let _IDRe18 = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/
  // let _IDre15 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/
  // 招生2.5需求 身份证号验证：最后1位x必须大写，否则无法提交并提示“最后一位x需要大写”
  const regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/
  // 如果通过该验证，说明身份证格式正确，但准确性还需计算
  if (regIdCard.test(idCard)) {
    if (type === 0) {
      return true
    }
    if (idCard.length == 18) {
      const idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) //将前17位加权因子保存在数组里
      const idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2) //这是除以11后，可能产生的11位余数、验证码，也保存成数组
      let idCardWiSum = 0 //用来保存前17位各自乖以加权因子后的总和
      for (let i = 0; i < 17; i++) {
        idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i]
      }
      const idCardMod = idCardWiSum % 11 //计算出校验码所在数组的位置
      const idCardLast = idCard.substring(17) //得到最后一位身份证号码
      //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
      if (idCardMod == 2) {
        return ['x', 'X'].includes(idCardLast)
      } else {
        //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
        if (idCardLast == idCardY[idCardMod]) {
          return true
        } else {
          return false
        }
      }
    }
  }
  return false
}

/**
 * @desc 验证身份证号码最后一位是否是x
 * @param {String} val 待验证值
 * @return Boolean
 */
export function validateIdCardX(idCard) {
  const reg = /.*x$/
  return reg.test(idCard) ? '最后一位x需要大写' : ''
}
// 接口api统一
export const getOpenId = code => {
  // 获取微信openid
  let baseUrl = import.meta.env.API_WX_URL
  return axios.get(baseUrl + '/api/wx/mp/info_by_code?code=' + code)
}
// 微信登录
export const onWxLogin = data => axios.post('/api/recruit/auth/weChat-login', data)
// 微信绑定
export const onWxBinding = data => axios.post('/api/recruit/auth/weChat-binding', data)
// 发送手机验证码
export const getPhoneCode = data => axios.post('/api/recruit/auth/mobile', data)
// 验证手机验证码
export const checkPhoneCode = params => axios.get('/api/recruit-v2/common/check-mobile', { params })
// 获取学校
export const getSchool = () => axios.get('/api/recruit/common/school')
// 注册用户信息筛选条件
export const getRegisterOption = () => axios.post('/api/recruit/register/screen')
// 咨询老师
export const getRegisterTeacher = () => axios.get('/api/recruit/register/teacher')
// 获取中考地点
export const getExaminationArea = params => axios.get('/api/recruit-v2/common/examination-area', { params })
// 获取中考配置
export const getExaminationConfig = params => axios.get('/api/recruit-v2/common/examination', { params })
// 手机号登陆
export const mobileLogin = data => axios.post('/api/recruit/auth/mobile-login', data)

// 报名页面展示年级
export const getGrade = params => axios.get('/api/recruit-v2/common/application-grade', { params })

// 高一直接报名
export const seniorOne = data => axios.post('/api/recruit-v2/auth/senior-one', data)
// 高一入学报名获取token
export const getSeniorToken = data => axios.post('/api/recruit-v2/auth/senior-token', data)
// 报名注册必填
export const directRegistrationRegister = data => axios.post('/api/recruit-v2/direct-registration/register', data)

// 高二续费
export const seniorTwo = data => axios.post('/api/recruit-v2/auth/senior-two', data)
// 续费绑定
export const renewBinding = data => axios.post('/api/recruit-v2/application/renew-binding', data)
// 续费绑定-学生个人信息页
export const getStudentInfo = params => axios.get('/api/recruit-v2/application/student-info', { params })
// 续费绑定--续费订单
export const getAccountOrder = params => axios.get('/api/recruit-v2/application/account-order', { params })

// 订单列表
export const getApplicationOrder = params => axios.get('/api/recruit-v2/direct-registration/application-order', { params })
// 订单详情
export const getOrderDetails = params => axios.get('/api/recruit-v2/family/order-details', { params })
// 查看后续费用
export const getOrderAfter = params => axios.get('/api/recruit-v2/family/tuition-after', { params })
// 取消订单
export const orderCancel = params => axios.get('/api/recruit-v2/family/cancel', { params })
// 待支付订单确认
export const getTreatPay = params => axios.get('/api/recruit-v2/family/treat-pay', { params })
//订单生成
export const createOrder = data => axios.post('/api/recruit-v2/family/order', data)
// 选择支付方式--分期数据
export const getStages = params => axios.get('/api/recruit-v2/family/is-stages', { params })
// 报名家长信息
export const getParent = params => axios.get('/api/recruit-v2/family/parent', { params })
// 去支付
export const orderPay = data => axios.post('/api/recruit-v2/family/pay', data)
// 查询支付状态
export const getPayStatus = data => axios.post('/api/recruit/family/pay-status', data)
// 查询签约状态
export const getSignStatus = params => axios.get('/api/recruit/contract/status', { params })
// 签约成功返回地址
export const getSignBackUrl = data => axios.post('/api/recruit/contract/sign', data)

// 招生3.1 复读生报名
// 注册
export const getRereadingRegistration = data => axios.post('/api/recruit-v2/rereading/registration', data)
// 信息列表
export const getRereadingForecastList = params => axios.get('/api/recruit-v2/rereading/forecast', { params })
// 订单列表
export const getRereadingOrderList = params => axios.get('/api/recruit-v2/reread/order-list', { params })
// 查看详情
export const getRereadingOrderInfo = params => axios.get('/api/recruit-v2/reread/signed-info', { params })
// 取消订单
export const getRereadingOrderCancel = data => axios.post('/api/recruit-v2/reread/pay-cancel', data)
// 待支付订单
export const getRereadingOrderPay = params => axios.get('/api/recruit-v2/reread/to-pay', { params })
// 改变签约手机号
export const getRereadingChangePhone = data => axios.post('/api/recruit-v2/reread/change-customer-phone', data)

// 招生2.7 专项教育
// 获取保过状态
export const getSupplementStatus = params => axios.get('/api/recruit-v2/supplement/status', { params })
// 签约前查询签约状态
export const getRereadSignStatus = params => axios.get('/api/recruit-v2/reread/sign-status', { params })

// 招生3.3 线下补录
// 2期
// 学生登记
export const getOfflineBinding = params => axios.get('/api/recruit-v2/offline-supplement/binding-student', { params })
// 3期
// 订单列表
export const getOfflineList = params => axios.get('/api/recruit-v2/direct-registration/application-order-bulu', { params })
// 检查状态
export const getOfflineCheckStatus = params => axios.get('/api/recruit-v2/approve-h5/check-status', { params })

// 学生入学报道
// 验证学生身份证号码
export const getEnrolInformation = params => axios.get('/api/recruit-v2/student-enrol/information', { params })
// 确认学生身份
export const getEnrolConfirm = data => axios.post('/api/recruit-v2/student-enrol/confirm', data)


// ZS-53 2024级预报名线下支付
// 预报名支付
export const getPrePay = data => axios.post('/api/recruit/family/pay', data)
