<template>
  <t-result :img="cur.img" :imgProps="imgProps" :hint="cur.hint"
    hint-style="font-size:14rem;color:#7C7C7C;"
  >
    <template #msg>
      <div v-show="status !== 3" style="margin-top: 22rem;font-size: 18rem;">{{cur.title}}</div>
      <div v-show="status === 3" style="margin-top: 22rem;font-size: 18rem;">{{cur.title1}}</div>
    </template>
    <t-button img="" @click="onHandle" type="primary" v-if="status != 1">{{ cur.btnText }}</t-button>
  </t-result>
</template>

<script setup>
import {useRouter,useRoute} from "vue-router";
import {onMounted} from "vue";
import tools from "../../utils/tools.js";
import {ref} from "vue";
import axios from "../../axios/index.js";
import {getLoginType} from "../../utils/utils.js";
// 状态: 2 取消
let data =[
  {
    img:"order-03.png",
    title:"支付失败",
    hint:"失败原因：银行卡余额不足",
    btnText:"继续支付"
  },
  {
    img:"order-04.png",
    title:"支付成功",
    hint:"系统正在报名中... 5",
    btnText:"重新报名"
  },
  {
    img:"order-02.png",
    title:"取消订单",
    hint:"当前订单已经取消, 如需此服务请重新报名",
    btnText:"重新报名"
  },
  {
    img:"order-05.png",
    title:"报名中",
    title1:"系统正在报名中…",
    hint:"大约需要15分钟…",
    btnText:"返回"
  },
  {
    img:"order-02.png",
    title:"在线支付暂未开放",
    hint:"您的报名信息咨询老师已经收到,请静候通知",
    btnText:"确定"
  },
]

const router = useRouter();
const route = useRoute();
let status = parseInt(route.query.status);
let cur = ref(data[status]);
tools.setWxTitle(cur.value.title)
const imgProps = status != 3?{
  width:'172rem',
  height:'140rem'
}:{
  width:'150rem',
  height:'64rem',
  style:"padding:50rem 72rem"
};
function onHandle() {
}
const loginType = getLoginType();
// 对不同状态做不同的操作
let timer = null;
switch (status) {
  case 2:// 订单取消
    onHandle = function () {
      switch (loginType) {
        case 13:
          router.push("/2024-online/student-list");
          break;
        default:
          router.replace("/register");
              break;
      }

    }
    break;
  case 1: // 支付成功
    let time = 5;
    timer = setInterval(()=>{
      time --;
      cur.value.hint = "系统正在报名中..."+time
      if (time === 0){
        // router.replace({query:{url:route.query.url,title:"报名"},path:"/webview"});
        window.location.href=route.query.url
        clearInterval(timer);

        // history.replaceState(null,null,route.query.url)
        // history.go(0)

      }
    },1000);
    break;
  case 0: // 支付失败
    onHandle = function () {
      router.replace({path:"/wait-pay",query:{
          order_number:route.query.order_number
        }})
    }
    break;
  case 3: // 待报名
    onHandle = function () {
      router.back();
    }
    break;
  case 4: // 在线支付未开放
    onHandle = function () {
      router.replace("/order")
    }
    break;

}

</script>

<style lang="scss" scoped>

</style>
