<template>
  <div class="t-icon" @click="$emit('click')" :style="{width:fontSize[0],height:fontSize[1]}">
    <i :style="{fontSize,color}" :class="classNames"></i>
  </div>
</template>

<script >
/**
 * @description 基于iconfont的图标组件
 * @attribute
 *  name : String  图标名
 *  size : String|Number 图标大小  Number默认单位: rem, string类型自己添加单位
 */
export default {
  name: "t-icon",
  emits:['click'],
  props:{
    name:String,
    size:{
      type:[Array,Number,String],
      default:()=>{
        return ["16rem"]
      }
    },
    color:String,
    type:{
      type:String,
      default:"default",
      validator(value){
        return ["default","icon-libs"].indexOf(value) !== -1;
      }
    }
  },
  data(){
    return {
      config:{
        default:{
          name:"iconfont",
          prefix:"icon-"
        },
        "icon-libs":{
          name:"icon-libs",
          prefix:"icon-"
        }
      }
    }
  },
  computed:{
    fontSize(){
      let size = this.size;
      if (Array.isArray(size)){
        for (let i = 0;i<size.length; i++){
          let item = size[i];
          if (/^[\d.]+$/.test(item)){
            size[i] = item + 'rem';
          }else {
            size[i] = item;
          }
        }
      }else{
        if (/^[\d.]+$/.test(size)){
          size += 'rem';
          size = [size];
        }
      }
      if (size.length === 1) size[1] = size[0];
      return size;
    },
    classNames(){
      const config = this.config;
      let item = config[this.type];
      return [item.name,item.prefix+this.name];
    }
  },
  install:function (app) {
    app.component("t-icon",this);
  }
}
</script>

<style scoped lang="scss">
  .t-icon{
    display:inline-block;
  }
</style>
