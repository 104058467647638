<template>
  <div class="teacher-select">
    <van-tabs v-model:active="active" shrink swipeable @change="change">
      <van-tab v-for="(item, index) in school_data">
        <template #title><span :style="index === active ? { color: '#417FF7', fontWeight: 'bold' } : { color: '#868686' }">{{ item.entity_school_name }}</span></template>
        <div class="teacher-list-box">
          <div v-if="item.teacher" style="position: relative;min-height: calc(100vh - 48px);">
            <van-index-bar v-if="Object.keys(item.teacher).length" :ref="test[index]" class="teacher-list" style="margin-bottom: 48px">
              <van-radio-group v-model="checked" direction="horizontal" @change="scrollY(index)">
                <van-radio :name="JSON.stringify({ text: '未与任何招生老师沟通', value: 0, active: active })" style="padding-left: 24px">
                  <van-cell v-show="(!!prop && !exclude.includes(prop)) && !notShowNoTeacher" title="未与任何招生老师沟通"></van-cell>
                </van-radio>
              </van-radio-group>
              <template v-for="(t, ZM) in item.teacher">
                <van-index-anchor :index="ZM" style="left: 10rem; background-color: transparent"></van-index-anchor>
                <van-radio-group v-model="checked" direction="horizontal" style="display: flex;flex-direction: column">
                  <van-radio v-for="teacher in t" :name="JSON.stringify({ text: teacher.name, value: teacher.id, active: active  })" style="padding-left: 24px">
                    <van-cell :title="teacher.name + '(' + teacher.mobile + ')'" style="width: 100%;"></van-cell>
                  </van-radio>
                </van-radio-group>
              </template>
              <div style="width: 100%;height: 70px"></div>
              <div style="padding: 12px 24px;box-sizing: border-box;position: absolute;bottom: 0;width: 100%;border-top: 1px solid #cccccc;background: #FFF">
                <van-button :disabled="!isNotEmpty(checked)" round size="normal" style="width: 100%" type="primary" @click="determine">确定</van-button>
              </div>
            </van-index-bar>
            <iframe v-else class="teacher-list" src="error?type=1" style="height: 300px;"/>
          </div>
          <div v-else>
            <div style="width: 100%;border-top: 1px solid #F5F5F7;padding: 20rem 40rem 30rem;box-sizing: border-box;display: flex;flex-flow: column;height: calc(100vh - 60rem)">
              <div style="flex: 1">
                <t-input style="margin-bottom: 10rem;" v-model="schoolName" placeholder="请输入" label="咨询老师所在校区"></t-input>
                <t-input style="margin-bottom: 10rem;" v-model="teacherName"  placeholder="请输入" label="咨询老师姓名"></t-input>
              </div>
              <t-button style="margin-top: 22rem;" type="primary" :disabled="tools.isEmpty([schoolName, teacherName],'||')" @click="onOtherSubmit">确认</t-button>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup>
import axios from '../../axios'
import {onMounted, ref} from 'vue'
import tools from '../../utils/tools.js'
import {showLoading} from '../../utils/utils.js'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {Dialog} from 'vant'


const active = ref(-1)
const name = ref([])
const test = ref([])
const school_data = ref({})
const exclude = ref(['belong_teacher_id'])
const store = useStore()
const router = useRouter()
const route = useRoute()
const school_id = route.query.value
const prop = route.query.prop
const notShowNoTeacher = route.query.notShowNoTeacher;
let commit = route.query.commitName || "setRegisterCache";
const schoolName = ref('') // 其他 - 校区
const teacherName = ref('') // 其他 - 老师姓名
const checked = ref('')
const tsOpt = ref([])
const disabled = ref(true)
showLoading(true)

function change() {
  if (!store.getters.registerCache || !store.getters.signupCache) {
    return checked.value = ''
  }
  if (store.getters.signupCache)
    if (store.getters.signupCache.entity_teacher.active === active.value) {
      checked.value = JSON.stringify(store.getters.signupCache.entity_teacher)
    } else checked.value = ''
  if (store.getters.registerCache)
    if (store.getters.registerCache.teacher_id.active === active.value) {
      checked.value = JSON.stringify(store.getters.registerCache.teacher_id)
    } else checked.value = ''
}

function scrollY(val) {
  if (document.querySelectorAll('.teacher-list')[val]) {
    test.value[val] = document.querySelectorAll('.teacher-list')[val].scrollTop
  }
}
function determine() {
  let val = JSON.parse(checked.value)
  if (val.value === 0)
    Dialog.confirm({
      title: '再次确认',
      message: '没有招生老师与您沟通介绍并指导入学报名吗？',
      confirmButtonText: '点错了',
      confirmButtonColor: 'rgba(0, 0, 0, 0.80)',
      cancelButtonText: '没有',
      cancelButtonColor: 'rgba(65, 127, 247, 1)'
    }).catch(err => {
      store.commit(commit, {
        [prop]: val
      })
      router.back()
    })
  else {
    store.commit(commit, {
      [prop]: val,
      diy_sign_teacher_name: null,
      diy_school: null,
      index: test.value,
      school: school_data.value[active.value].entity_school
    })
    router.back()
  }
}

function isNotEmpty(value) {
  if (value === '') {
    return false;
  }

  if (value === 0 || value === '0') {
    return true;
  }

  return true;
}
function onOtherSubmit(){
  if (schoolName.value.length>30){
    Dialog.alert({
      title: '提示',
      message: '校区长度不能超过30个字符'
    })
    return
  }
  if(teacherName.value.length > 20){
    Dialog.alert({
      title: '提示',
      message: '老师姓名长度不能超过20个字符'
    })
    return
  }
  if(schoolName.value && teacherName.value){
    let res = {};
    res["diy_school"] = !tools.isEmpty(schoolName.value)?schoolName.value:undefined;
    res["diy_sign_teacher_name"] = !tools.isEmpty(teacherName.value)?teacherName.value:undefined;
    res["active"] = !tools.isEmpty(teacherName.value) ? teacherName.value : undefined;
    res["school"] = -1;
    res[prop] = {
      text:teacherName.value,
      value: -1,
      active: active.value,
      name: schoolName.value,
    }
    store.commit(commit, {
      ...res
    })
    router.back()
  }
}

axios.get('/api/recruit/register/teacher').then(res => {
  school_data.value = res
  if (school_id != null) {
    for (let i = 0; i < res.length; i++) {
      let item = res[i]
      if (item.consulting_school === Number(school_id)) {
        active.value = i
        break
      }
    }
  }
  school_data.value.push({entity_school: -1, entity_school_name: "其他",})
  test.value = Array.from({length: school_data.value.length - 1}, (_) => 0)
  showLoading(false)
  if (store.getters.signupCache)
    if (school_data.value.some(obj => obj.entity_school === store.getters.signupCache.school)) {
      active.value = store.getters.signupCache.entity_teacher ? store.getters.signupCache.entity_teacher.active : 0
      checked.value = store.getters.signupCache.entity_teacher ? JSON.stringify(store.getters.signupCache.entity_teacher) : ''
      if (store.getters.signupCache?.entity_teacher.value === -1) {
        teacherName.value = store.getters.signupCache.entity_teacher.text
        schoolName.value = store.getters.signupCache.entity_teacher.name
      }
      setTimeout(() => {
        document.querySelectorAll('.teacher-list')[active.value].scrollTop = store.getters.signupCache.index[active.value]
      }, 500)
    }
  if (store.getters.registerCache)
    if (school_data.value.some(obj => obj.entity_school === store.getters.registerCache.school)) {
      active.value = store.getters.registerCache.teacher_id ? store.getters.registerCache.teacher_id.active : 0
      checked.value = store.getters.registerCache.teacher_id ? JSON.stringify(store.getters.registerCache.teacher_id) : ''
      if (store.getters.registerCache?.teacher_id.value === -1) {
        teacherName.value = store.getters.registerCache.teacher_id.text
        schoolName.value = store.getters.registerCache.teacher_id.name
      }
      setTimeout(() => {
        document.querySelectorAll('.teacher-list')[active.value].scrollTop = store.getters.registerCache.index[active.value]
      }, 500)
    }
}).catch(res => {
  showLoading(false)
})
if (store.getters.registerCache) {

}
onMounted(async () => {

})
</script>

<style lang="scss" scoped>
.teacher-list-box {
  height: calc(100vh - 44px);
  position: relative;
  overflow: hidden;
}
iframe.teacher-list {
  border: none;
  width: 100%;
  height: 100%;
}
.teacher-list {
  max-height: calc(100vh - 44px);
  overflow: scroll;
  :deep(.van-index-bar__sidebar) {
    position: absolute;
  }
  :deep(.van-index-anchor--sticky) {
    position: absolute;
    left: 0 !important;
    transform: translate3d(0px, 0, 0px) !important;
  }
  --van-cell-vertical-padding: 20px;
}
:deep(.van-tabs__line) {
  background-color: transparent;
  background-image: url('https://main.pro.honghueducation.com:8003/bf-static/pre-register/tab_line.png');
  background-repeat: no-repeat;
  height: 10px;
  width: 30rem;
  background-size: 100% 100%;
}
</style>
