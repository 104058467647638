<template>
<div class="t-img">
  <van-image v-bind="$props" :src="comSrc"  @load="$emit('load',$event)" @error="$emit('error',$event)"></van-image>
</div>
</template>

<script>
import {getCurrentInstance} from "vue";
import {Image} from "vant"
export default {
  name: "TImg",
  props:Image.props,
  emits:Image.emits,
  setup(props){
    const {proxy} = getCurrentInstance();
    const base_src = proxy.$resource;
    let comSrc = "";
    if (props.src[0] === "/")comSrc = props.src;
    else comSrc = base_src+props.src;
    return {
      base_src,
      comSrc
    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>

<style scoped>
.t-img{
  display: inline-block;
  line-height: 0;
}
</style>
