import tDialog from "./dialog.vue"
import {render,h,ref} from "vue"
import {createRenderer} from "vue";

export class Dialog{
    box = null;
    constructor(options) {
        options = options || {};
        let {message,confirmText,cancelText,onConfirm,onCancel,type,clickMaskClose,onMask,layout} = options;
        this.box = document.createElement("div");
        let close = this.close.bind(this);
        let el = h(tDialog,{
            message,
            confirmText,
            clickMaskClose,
            cancelText,
            layout,
            type,
            onConfirm:()=>{
                if (!onConfirm) close();
                else onConfirm(close);
            },
            onCancel:()=>{
                if (!onCancel)close();
                else onCancel(close);
            },
            onMask(){
                if(onMask != null)onMask(close);
                if (clickMaskClose)close();
            },
            visible:true
        })
        render(el,this.box);
    }
    close(){
        this.box.remove();
    }
    show(elem = document.body){
        elem.append(this.box);
    }
}

/**
 * 显示弹窗
 * @param {{message:String,confirmText?:String,cancelText?:String,type?:"confirm"|"alert",clickMaskClose:Boolean,onConfirm?:Function,onCancel?:Function}} options 配置
 */
export function dialog(options={}){
    (new Dialog(options)).show();
}
const types = ["confirm","alert"];

types.forEach(item=>{
    /**
     * 弹窗函数
     * @param options {{message:String,confirmText?:String,cancelText?:String,clickMaskClose:Boolean,onConfirm?:Function,onCancel?:Function}}
     * @return {Promise<unknown>}
     */
    dialog[item] = function(options){
        return dialog({
            ...options,
            type:item
        });
    }
})
export default dialog;
