<template>
  <div class="t-select">
    <div class="t-shown" v-if="styleType==='input'">
      <van-field :placeholder="placeholder" :modelValue="modelVal" readonly @click="onClick">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
    </div>
    <div class="t-shown t-shown-cell" v-else-if="styleType==='cell'">
      <van-cell :title="label" :value="modelVal||placeholder" @click="onClick" is-link>

      </van-cell>
    </div>
<!--    {{defaultIndex}}-->
    <!--  弹出选择框  -->
    <van-popup v-model:show="picker" position="bottom" @click.stop>
      <van-picker :default-index="defaultIndex" :title="title" ref="pic" :columns="options" @cancel="$emit('cancel',$event);picker = false;" v-bind="pickerProps" @confirm="onConfirm">
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import {ref} from "vue";
import tools from "../../utils/tools.js";

export default {
  name: "TSelect",
  emits:["update:modelValue","change","confirm","cancel","onClick"],
  computed:{
    modelVal(){
      if(typeof this.modelValue === 'object' && this.modelValue != null)
        return this.modelValue.text;
      else return this.modelValue;
    }
  },
  props:{
    modelValue:[String,Object,Number],
    // 展示样式 , input 跟input框的展示一致 , cell 像cell一样的展示方式
    styleType:{
      type:String,
      default:'input',
      validator(value){
        return ['input','cell'].includes(value)
      }
    },
    // label 只在cell模式下有效
    label:String,
    // 只读属性, 不会触发popup
    readonly:Boolean,
    // 弹出框标题
    title:String,
    // 占位信息
    placeholder:String,
    // 选项
    options:Object,
    // vant 官方picker 属性
    pickerProps:Object
  },
  data(){
    return {
      modifyType:1, // 更改模式 , 0. 组件内部更改 , 1. 绑定的moduleValue更改
      defaultIndex:0
    }
  },
  watch:{
    modelValue(n){
      this.$emit("change",n);
      if (this.modifyType === 1){
        this.setDefaultIndex();
      }
      this.modifyType = 1;
    }
  },
  mounted(){
  },
  setup(){
    let picker = ref(false)
    return {
      picker
    }
  },
  methods:{
    findIndex(item){
      if (!Array.isArray(item))item = [item];
      let indexes = [];
      let pop = item.pop();
      try{
        this.DFS(this.options,(e)=>{
          if (e.value === pop){
            indexes.push(e.index);
            if (!item.length)throw '跳出循环';
            pop = item.pop();
          }
        },{
          child:'children'
        })
      }catch (e) {

      }
      return indexes;

    },
    setDefaultIndex(){
      let arg = [];
      if (Array.isArray(this.modelValue)) arg = this.modelValue.map((item)=>item.value)
      else if(!!this.modelValue) arg = [this.modelValue.value]
      else arg = [];
      let indexes = this.findIndex(arg);
      this.defaultIndex = indexes.join(",");
    },
    DFS(data, callback = ()=>{}, options = {}) {
      let _options = Object.assign({}, {
        child: "data"
      }, options);
      let {
        child
      } = _options;
      if (!!data) {
        let stack = [];
        if (Array.isArray(data))
          for (let i = data.length - 1; i >= 0; i--) {
            let item = data[i];
            item._tier = 0;
            item.index = i;
            stack.push(item);
          }
        else if (typeof data === "object") {
          data._tier = 0;
          stack.push(data);

        }
        while (stack.length > 0) {
          let item = stack.pop();
          let tier = item._tier;
          tier ++;
          callback(item);
          let children = item[child] || [];
          for (let i = children.length - 1; i >= 0; i--) {
            children[i]._tier = tier;
            stack.push(children[i]);
          }
        }
      }
    },
    onConfirm(e){
      this.$emit("update:modelValue",e);
      this.$emit("confirm",e);
      this.picker = false;
      this.modifyType = 0; //
    },
    onClick(){
      if (!this.readonly)
        this.picker = true;
      this.$emit("onClick");
      if (this.modelValue == null)return;
      if (!this.$refs.pic)
        this.setDefaultIndex();
      else{
        let val = this.$refs.pic.getValues().map(item=>item.value).join(",");
        let curVal = Array.isArray(this.modelValue)?this.modelValue.map(item=>item.value).join(","):this.modelValue.value + "";
        if (val !== curVal)this.setDefaultIndex();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.t-select{
  --van-cell-background-color:#eee;
  .t-shown-cell{
    --van-cell-background-color:#fff;
    --van-cell-horizontal-padding:0;
    --van-cell-vertical-padding:0;
    padding: 10rem 0;
    border-bottom: 1px solid rgba(247, 247, 247, 1);
    :deep(.van-cell){
      background-color: transparent;
    }
  }
  .t-cell{
    border-radius: 100rem;
  }
}
</style>
