import store from "../store/index.js";
import tools from "./tools.js";
import constants, {routerMap} from "./constants.js";
import mutations from "../store/mutations.js"
import {findValueByOptions} from "../components/Form/utils.js";

/**
 * @description 格式化select所需的数据
 * @param {Array} data 数据源
 * @param {string} keyStr 数据源中用于text的字段名
 * @param {string} valStr 数据源中应用于value的字段名
 * @param {null|Function} callback 回调
 */
export function formatSelect(data,keyStr="",valStr="",callback=null) {
    callback = callback || (()=>{});
    if (Array.isArray(data))
        return data.map(item=>{
            let text = item[keyStr];
            let value = item[valStr];
            let res = {
                text,
                value
            };
            return callback(res, item) || res;
        })
    else{
        let result = [];
        for (const dataKey in data) {
            let item = data[dataKey];
            let res = {
                text:item,
                value:dataKey
            };
            let cbr = callback(res,item) || res;
            result.push(cbr)
        }
        return result;
    }
}

/**
 * @description 更改loading状态
 * @param {boolean} state 加载状态
 * @param {string|null} message 加载状态
 */
export function showLoading(state=true,message="请稍后"){
    if (typeof state === "string") {
        message = state;
        state =true;
    }
    store.commit("setLoading",state);
    if (message)store.commit("setLoadingText",message);
    if (!state)store.commit("setLoadingText",null);
}

export function hideLoading(){
    store.commit("setLoading",false);
    store.commit("setLoadingText",null);
}

/**
 * @description 设置缓存
 * @param sessionKey session
 * @param data
 * @param isSession
 * @param isReset
 * @return {any}
 */
export function setCache(sessionKey,data,isSession = true,isReset = false){
    let saveData = tools.setSession;
    let getData = tools.getSession;
    if (!isSession){
        saveData = tools.setStorage;
        getData = tools.getStorage;
    }
    if (data == null){
        saveData(sessionKey,null);
        return null;
    }
    let result = data;
    if (!isReset && (typeof data === "object" && !Array.isArray(data))){
        const old = getData(sessionKey)||{};
        result = Object.assign({},old,data);
    }
    saveData(sessionKey,result);
    return result;
}

/**
 * @description 格式化缓存数据为axios 可用的参数
 * @param {Object} data
 * @param {Function} callback
 */
export function formatCacheToAxios(data,callback = ()=>undefined){
    if (!data)return {};
    let result = {};
    for (const dataKey in data) {
        let item = data[dataKey];
        if (item == null || dataKey[0] === "_" || item.ignore)continue;
        let cbVal = callback(result,item,dataKey);
        if (cbVal != null){
         result = cbVal;
        }else if (Array.isArray(item)){
            let len = item.length;
            if (len === 0)continue;
            else if (typeof item[0] === 'string')
                result[dataKey] = item.join(",");
            else if(item[len-1].value)
                result[dataKey] = item[len-1].value;
            else result[dataKey] = item;
        }else if (typeof item === "object"){
            if (!!item)
                result[dataKey] = item && item.value;
        }else  result[dataKey] = item;
    }
    return result;
}

/**
 * @description 用于地址映射
 * @param address 地址数据
 */
export function addressMap(address){
    let res = {};
    tools.DFS(address,function (item) {
        res[item.address_code] = item;
    },{
        child:"children"
    });
    return res;
}

/**
 * @desc 清除缓存 当key为nul||undefined 的时候 , 清除所有缓存
 * @param {string | null | Array} key
 */
export function clearCache(key= null) {
    if (key == null){
        let keys = tools.getSession("SESSION_KEYS");
        if ( keys != null)
            keys.forEach(item=>{
                if (item !== constants.openId) {
                    tools.setSession(item,null);
                    mapCacheToState(item);
                }
            })
    }else if (typeof key === "string"){
        // tools.setSession(key,null);
        mapCacheToState(key)
    }else{
        key.forEach(item=>{
            // tools.setSession(item,null);
            mapCacheToState(item)
        })
    }
}

/**
 * @description 映射缓存到state中(通过缓存中的key, 执行store中的commit)
 * @param key
 * @param val
 */
export function mapCacheToState(key,val=null) {
    Object.keys(mutations).forEach(item=>{
        let f = item[3].toLowerCase();
        let k = item.slice(4);
        k = f + k;
        if (constants[k] === key){
            store.commit(item,val);
        }
    })
}

const winH = window.innerHeight;
export function sh(height) {
    return winH / 724 * height
}

/**
 * @description 更改路由key
 * @param router 路由
 * @param name name
 */
export function changeRouterKey(router,name) {
    let reg = new RegExp(`^${name}\\d*$`)
    let list = router.getRoutes();
    let signup = null;
    for (let r of list){
        if (reg.test(r.name)) {
            signup = r;
            router.removeRoute(r.name);
            signup.name = name + (new Date()).getTime();
            router.addRoute(signup);
            break;
        }
    }
}
// 获取基地址
export function getBaseSrc(){
    let env = import.meta.env.MODE;
    if (env !== "dev_local"){
        return "/"+routerMap[env]+'/'
    }else return "/"
}

/**
 * @desc 获取合同目录地址
 * @param src 可选, 合同地址目录下的资源地址
 * @return {string} 完整地址
 */
export function getContractSrc(src=""){
    return getBaseSrc() + "contract/" +src
}

/**
 * @description 设置级联数据
 * @param data 数据源
 * @param opt 配置
 * @return {*[]|*}
 */
export let setCascader = (data,opt = {
})=>{
    opt = Object.assign({},{
        text:"name", // 用于显示的text
        value:"id", // value字段
        children:"children" // 子级字段
    },opt);
    if (data == null)return null;
    return data.map(item=>{
        let children = setCascader(item[opt.children],opt);
        let res = {
            text:item[opt.text],
            value:item[opt.value]
        }
        if (children != null)res.children = children;
        return res;
    })
}

/**
 * @description 顶部进度条
 * @return {(function(*): void)|*}
 */
export function setProgress(){
    let timer = null;
    // rate 进度  如果不填写 , 则走固有逻辑
    return function(rate){
        if (timer){
            clearTimeout(timer);
            store.commit("setProgress",0);
        }
        let progress = store.state.progress;
        if (rate !== 100)
            store.commit("setProgress",rate);
        if (progress === 0 && rate == null){
            timer = setTimeout(()=>{
                store.commit("setProgress",50);
                timer = setTimeout(()=>{
                    store.commit("setProgress",80);
                },200)
            },200)
        }else if(rate === 100){
            clearTimeout(timer);
            store.commit("setProgress",100);
            timer = setTimeout(()=>{
                store.commit("setProgress",0);
                clearTimeout(timer)
            },500)
        }
    }

}

export function stringToFunction(funcStr){
    if (typeof funcStr === 'string') {
        if (/^\s*function/.test(funcStr)){
            return (new Function("return " + funcStr))();
        }else if(/return/.test(funcStr)){
            return new Function(funcStr);
        }else{
            return new Function("return "+`eval("${funcStr}")`);
        }
    }
    else if (typeof funcStr === 'function')
        return funcStr;
    else return null;
}
export function isFunctionString(str){
    if (typeof str !== "string")return false;
    let reg = /(^\s*function)|(\(\)\=>)|(\w+\(*.\)\{)/
    return reg.test(str);

}

/**
 * 通过uid 查找组件
 * @param node 当前节点
 * @param uid
 * @param direction 查找反方向  1(0x01) :向下  2(0x10) 向上 3(0x11):双向
 */
export function findComponentByUid(node,uid,direction = 0){
    if (direction & 0x01){
        try {
            tools.DFS(node,function (item) {

            },{
                child:"$children"
            })
        }catch (e) {

        }
    }
    if (direction & 0x10){

    }
}
/**
 * 打开loading , 并设置百分比
 * @param num
 * @return {function(): number}
 */
export function setLoadingRate(num){
    let successNum = -1;
    return function(){
        successNum ++;
        showLoading(true,"请稍后("+Math.floor(100/num*successNum)+"%)...");
        return successNum
    }
};

/**
 * 设置登录模式
 * @param type
 */
export function setLoginType(type){
    tools.setSession(constants.loginType,type);
    setLoginPath(null,type);
}

/**
 * 获取登录模式
 */
export function getLoginType(){
    let type = tools.getSession(constants.loginType);
    if (type == null)
        return 0;
    else return Number(type)
}


/**
 * 将指定的页面加入缓存
 * @param name
 */
export function setToKeepList(name){
    let list = store.getters.keepList;
    for (let item of list){
        if (item === name)return;
    }
    list.push(name);
    store.commit("setKeepList",list);
}

/**
 * 将指定页面从缓存中去除
 * @param  {string} name
 */
export function removeFromKeepList(name){
    let list = store.getters.keepList;
    let index = list.indexOf(name);
    if (index > -1)list.splice(index,1);
    store.commit('setKeepList',list);
}

/**
 * 获取年份配置(options)
 * @param {number} prev 今年向前推多少年
 * @param {number} next 今年向后推多少年
 */
export function getYearOptions(prev = 0,next = 0){
    let year = (new Date()).getFullYear();
    let res = [];
    for (let i = -prev;i <=next; i++){
        res.push({
            text:year+i,
            value:year+i
        })
    }
    return res;

}

/**
 * 检测是否为微信内置浏览器
 */
export function checkBrowser(){
    // 非dev环境打开浏览器检测
    let flag = false;
    if (import.meta.env.MODE.indexOf('dev') === -1)
    // if (true)
    {
        let nv = navigator.userAgent.toLowerCase();
        if (/micromessenger/.test(nv)){
            flag = true;
        }
    }else flag = true;
    store.commit("setMicroMessenger",{flag});
}
export function getCurPathInfo() {
    return getPathInfo(getLoginType());
}
export function setCurPathInfo(payload) {
    setPathInfo(payload,getLoginType());
}
export function getPathInfo(type= null){
    if (type == null)return store.getters.pathInfo;
    return store.getters.pathInfo[type] || {};
}
export function setPathInfo(payload , type = null){
    if (type == null) {
        store.commit("setPathInfo",payload);
    }else{
        let pathInfo = getPathInfo();
        pathInfo[type] = payload;
        store.commit("setPathInfo",pathInfo);
    }
}
export function setLoginPath(path = null,type = getLoginType()){
    path = path || location.pathname+location.search;
    type = type || getLoginType();
    let info = getPathInfo(type);
    info.login = path;
    setPathInfo(info,type);
}
export function getLoginPath(){
    let type = getLoginType();
    let info = getPathInfo(type);
    return info.login;
}
/**
 * 表单工具(配置表单相关)
 * @type {{formatConfig(*): *}}
 */
export const formUtils = {
    /**
     * 格式化配置数据 , 将function 转化为字符串
     * @param {Array|null} config
     * @return {*}
     */
    formatConfig(config){
        if (!config)return config;

        let toString = (item)=>{
            for (let k in item){
                let val = item[k];
                if (typeof val === "function"){
                    let str =val.toString();
                    if (val.prototype == null) {
                        if (str.indexOf('function') !== 0)
                            str = str.replace(val.name, "function");
                        else
                            str = str.replace(val.name, "");
                    }
                    item[k] = str;
                }else if(typeof val === "object")toString(item[k])
            }
            return item;
        }
        config.forEach(item=>{
            if (Array.isArray(item))this.formatConfig(item);
            else {
                toString(item)
            }
        })
        return config;
    },
    /**
     * 插入表单配置
     * @param {Number} index 插入位置
     * @param data 插入的数据
     * @param {Array|Object|null} config 原始配置(默认为store.getters.formConfigCache)
     */
    insertConfig(index,data,config = null){
        this.replaceConfig(index,data,0,config);
    },
    /**
     * 替换表单配置
     * @param index 替换位置
     * @param data  替换数据
     * @param deleteCount 删除数量
     * @param {Array|Object|null} config 原始配置(默认为store.getters.formConfigCache)
     */
    replaceConfig(index,data,deleteCount=1,config = null){
        if (config == null){
            config = store.getters.formConfigCache;
        }
        if (deleteCount === 1)config[index] = data;
        else
            config.splice(index,deleteCount,data);
        store.commit("setFormConfigCache",config);
    },
    /**
     * 将数据转化为预览数据的配置
     * @param {Object} data json数据
     * @param {Object} property t-cell组件属性
     * @return {Array}
     */
    formatShowConfig(data,property={}){
        let res = [];
        Object.keys(data).forEach(item=>{
            let val = data[item];
            if (val && typeof val === 'object')val = val.text;
            res.push({
                type:"show",
                label:item,
                val,
                property
            })
        })
        return res;
    },
    /**
     * 通过prop查找配置中的对应项
     * @param {string} prop 表单的prop
     * @param {Array} config 表单配置组
     * @return {null|Object}
     */
    getConfigItemByProp(prop,config){
        if (!config)return null;
        for (let item of config){
            if (Array.isArray(item)) {
                let res = this.getConfigItemByProp(prop, item);
                if (res)return res;
            }else if (item.prop === prop)return item;
        }
        return null;
    },
    /**
     * 设置配置项的options
     * @param prop 该项的唯一prop
     * @param options 需要设置的options
     * @param config 配置组
     * @return {boolean} 是否成功
     */
    setOptions(prop,options,config){
        let item = this.getConfigItemByProp(prop,config);
        if (item){
            item.options = options;
            return true;
        }else return false;
    },
    /**
     * 通过value从options中查找对应的选项
     * @param {number|string} value
     * @param {Array} options
     */
    getOption(value,options = []){
        for (let item of options){
            if (item.value === value)return item;
        }
        return null;
    },
    /**
     * 通过配置项的prop从配置中查找对应的选项
     * @param value 查找内容
     * @param prop 查找的prop
     * @param config 配置项
     * @return {*}
     */
    getOptionByConfigItem(value,prop,config){
        let options = this.getConfigItemByProp(prop,config);
        return this.getOption(value,options.options);
    },
    formatCacheToAxios,
    formatSelect,
    clearFormCache(){
        clearCache([constants.formDataCache,constants.formConfigCache,constants.formExtraCache])
    },
    findValueByOptions,
    refreshCache(i1,i2,config){
        let configCache = store.getters.formConfigCache;
        configCache[i1] = config[i2];
        store.commit("setFormConfigCache",configCache);
    },
    // 卸载所有配置相关内容
    unmountConfig() {
        store.commit("setFormConfigCache",null);
        store.commit("setFormExtraCache",null);
        store.commit("setFormDataCache",null);
    }
}
