<template>
  <div class="z-page-wrapper">
    <FlexPage>
      <div class="z-pay-box">
        <div class="z-pay-amount" v-if="showPrice">
          <div class="amount-l">
            <img class="img" src="@/assets/imgs/zp-12.png" alt="" />
            <span class="text">待支付金额</span>
          </div>
          <div class="amount-r">{{ price }}</div>
        </div>
        <div class="z-pay-people">
          <div class="title">
            <img src="@/assets/imgs/zp-03.png" alt="" />
            <span>选择付款人</span>
          </div>
          <div class="z-bg-white content">
            <div
                class="item"
                :class="[data.payer === 1 && 'active']"
                @click="handleChange('payer', 1)"
            >
              本人支付
            </div>
            <div
                class="item"
                :class="[data.payer === 2 && 'active']"
                @click="handleChange('payer', 2)"
            >
              他人代付
            </div>
          </div>
        </div>
        <div class="z-pay-method z-bg-white">
          <div class="title">
            <img src="@/assets/imgs/zp-04.png" alt="" />选择付款方式
          </div>
          <div
              class="item"
              :class="[data.payment_method === 'WXPay' && 'active']"
              @click="handleChange('payment_method', 'WXPay')"
          >
            <img src="@/assets/imgs/zp-05.png" alt="" />微信支付（推荐）
          </div>
          <div
              class="item"
              :class="[data.payment_method === 'Alipay' && 'active']"
              @click="handleChange('payment_method', 'Alipay')"
          >
            <img src="@/assets/imgs/zp-06.png" alt="" />支付宝支付
          </div>
          <div
              v-if="showOffline"
              class="item"
              :class="[data.payment_method === 'OfflinePayment' && 'active']"
              @click="handleChange('payment_method', 'OfflinePayment')"
          >
            <img src="@/assets/imgs/2024/pay-offline.png" alt="" />去财务处缴费
          </div>
        </div>
        <div class="z-pay-installments" v-if="instalments">
          <div class="title">
            <img src="@/assets/imgs/zp-07.png" alt="" />分期计划（特批）
          </div>
          <div class="content">
            <div
                class="child z-bg-white"
                v-for="item in instalments"
                :key="item.stages_id"
                :class="[item.select && 'active']"
            >
              <div class="sub">分{{ item.plan_num }}期</div>
              <div class="des">首次需付 {{ item.firstMoney }}</div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex" >
          <div class="flex-2 mr-1" v-if="showCancel">
            <van-button class="z-button" block type="primary" plain @click="$emit('cancel')">{{ cancelText }}</van-button>
          </div>
          <div class="flex-4" v-if="showConfirm">
            <van-button :disabled="disabled" class="z-button" block type="primary" @click="$emit('confirm')">{{ confirmText }}</van-button>
          </div>
        </div>
      </template>
    </FlexPage>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, reactive, computed} from "vue"
const props = defineProps({
  price:Number,
  showPrice:Boolean,
  showOffline:Boolean,
  showCancel:{
    type:Boolean,
    default:true
  },
  showConfirm:{
    type:Boolean,
    default:true
  },
  cancelText:{
    type:String,
    default:'取消支付'
  },
  confirmText:{
    type:String,
    default:'立即支付'
  },
  /**
   * 分期付款数据
   * stages_id: 分期id
   * plan_num: 分期期数
   * firstMoney: 首付款金额
   * select: 是否选中
   * @type {Array<{stages_id:number,plan_num:number,firstMoney:number,select:boolean}>}
   */
  instalments:Array,
})
const data = reactive({
  payer:null, // 支付者
  payment_method:null, // 支付方式
})

const disabled = computed(()=>data.payer === null || data.payment_method === null)
const handleChange = (type, idx) => {
  if (data[type] === idx) {
    data[type] = null;
    return;
  }
  data[type] = idx;
};
</script>

<style lang="scss" scoped>

.z-pay {
  &-amount {
    padding: 15rem 17.5rem;
    margin-bottom: 32rem !important;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    border-radius: 6rem;
    border-bottom: solid 1px #e8e8e8;
    .img {
      width: 17.5rem;
      height: 20rem;
      margin-right: 8rem;
    }
    .text {
      font-size: 16rem;
    }
    .amount-l {
      display: flex;
      align-items: center;
    }
    .amount-r {
      font-size: 18rem;
      color: #f74156;
    }
  }
  &-box {
    padding: 40rem 17.5rem 20rem;
    .title {
      height: 60rem;
      padding-left: 20rem;
      font-size: 16rem;
      color: rgba($color: #000000, $alpha: 0.8);
      font-weight: 500;
      display: flex;
      align-items: center;
      img {
        margin-right: 15rem;
      }
    }
    .child,
    .item {
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        border-radius: 50%;
      }
      &::before {
        z-index: 1;
        right: 22rem;
        width: 8rem;
        height: 8rem;
      }
      &::after {
        right: 17rem;
        width: 16rem;
        height: 16rem;
        background-color: var(--van-white);
        border: solid 1rem rgba(0, 0, 0, 0.2);
      }
      &.active {
        background-color: #eee;
        &::before {
          background-color: var(--van-primary-color);
        }
      }
    }
    .item {
      height: 60rem;
      padding-right: 17rem;
      display: flex;
      align-items: center;
      &::after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    > *:not(:last-child) {
      margin-bottom: 12rem;
    }
  }
  &-people {
    height: 180rem;
    padding: 3rem;
    background: linear-gradient(180deg,#DEEFFF , #fff);
    background-size: cover;
    border-radius: 6rem;
    .title {
      color: #498FF9;
      img {
        width: 19rem;
        height: 23rem;
      }
    }
    .content {
      font-size: 14rem;
      color: rgba($color: #000000, $alpha: 0.8);
      border-radius: 6rem;
      overflow: hidden;
      .item {
        padding-left: 53rem;
      }
    }
  }
  &-method {
    border-radius: 6rem;
    .title {
      img {
        width: 21rem;
        height: 20rem;
      }
    }
    .item {
      padding-left: 20rem;
      img {
        width: 21rem;
        margin-right: 15rem;
      }
    }
  }
  &-installments {
    .title {
      img {
        width: 22rem;
        height: 16rem;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .child {
        width: calc(50% - 6rem);
        height: auto;
        padding: 18rem;
        margin-bottom: 12rem;
        box-sizing: border-box;
        flex-direction: column;
        align-items: start;
        border-radius: 6rem;
        &:nth-child(2n) {
          margin-left: 12rem;
        }
        &::before {
          top: 25rem;
        }
        &::after {
          top: 20rem;
        }
        .sub {
          margin-bottom: 17rem;
          font-size: 14rem;
          color: rgba($color: #000000, $alpha: 0.8);
        }
        .des {
          font-size: 12rem;
          color: rgba($color: #000000, $alpha: 0.4);
        }
      }
    }
  }
}
</style>
