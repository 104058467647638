import tools from '../utils/tools.js'
import constants from '../utils/constants.js'
import state from "./state.js";
export const mapKeys = ['signupCache', 'registerCache', 'nationSource', 'addressSource', 'baseConfig', 'userBaseInfo']
export default {
  // 预报名相关
  signupCache: state => state.signupCache || tools.getSession(constants.signupCache),
  registerCache: state => state.registerCache || tools.getSession(constants.registerCache),
  nationSource: state => state.signupCache || tools.getSession(constants.nationSource),
  addressSource: state => state.addressSource || tools.getSession(constants.addressSource),
  baseConfig: state => state.baseConfig || tools.getSession(constants.baseConfig),
  userBaseInfo: state => state.userBaseInfo || tools.getSession(constants.userBaseInfo),
  // 报名相关
  // 表单数据缓存
  formDataCache: state => {
    let data = state.formDataCache
    if (!!data && Object.keys(data).length > 0) {
      return data
    }
    data = tools.getSession(constants.formDataCache)
    if (!!data) return data
    else return {}
  },
  // 表单配置缓存
  formConfigCache: state => state.formConfigCache || tools.getStorage(constants.formConfigCache),
  // 身份证
  idCard: state => state.idCard || String(tools.getSession(constants.idCard)),
  keepList: state => state.keepList || tools.getSession(constants.keepList),
  // 微信相关信息
  microMessenger:state=>state.microMessenger||tools.getStorage(constants.microMessenger),
  formExtraCache:state=>(tools.getStorage(constants.formExtraCache) || state.formExtraCache),
  pathInfo:state=>tools.getSession(constants.pathInfo)||state.pathInfo
}
