<template>
  <div v-if="address == null"></div>
  <van-index-bar class="address-list" :index-list="indexList" v-else-if="Object.keys(address).length">
    <template v-for="(item,key) in address">
      <van-index-anchor :index="key" style="left: 10rem;background-color: transparent;"></van-index-anchor>
      <van-cell v-for="n in item" :title="n.address_name" @click="onSelected(n)"></van-cell>
    </template>
  </van-index-bar>
  <iframe class="address-list" src="error?type=1" v-else></iframe>
</template>

<script setup>
import {useRouter,useRoute} from "vue-router";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import axios from "../../axios/index.js";
import tools from "../../utils/tools.js";
import {showLoading} from "../../utils/utils.js";
const route = useRoute();
const router = useRouter();
const store = useStore();
const address = ref(null); // 地址数据
const title = decodeURI(route.query.title); // 页面title
const code = route.query.code; // 行政编码
const prop = route.query.prop; // 字段名
const type = route.query.type; // 类型 , 0|undefined 为注册 , 1 为表单
const cache = store.getters.addressSource;
const indexList = computed(()=>{
  let index = [];
  if (address.value!=null)
    for (const indexKey in address.value) {
      index.push(indexKey)
    }
  return index;
})
tools.setWxTitle(title);
getData();
// 获取数据
function getData() {
  showLoading(true)
  if (!!cache && code === "000000"){
    address.value = cache
    showLoading(false)
  }else{
    axios.get("/api/recruit/common/area",{
      params:{
        address_code:code
      }
    }).then(res=>{
      showLoading(false)
      let addr = formatAddr(res);
      address.value = addr;
      if (code === "000000")
        store.commit("setAddressSource",addr);
    }).catch(err=> {
      address.value = {};
      showLoading(false);
    })
  }
}

/**
 * @description 格式化地址数据为所需格式
 * @param res
 * @return {{}}
 */
function formatAddr(res){
  let result = {};
  res.forEach(item=>{
    const letter = item.letter;
    result[letter] == null ? result[letter] = [item]:result[letter].push(item);
  })
  return result;
}

// 当省份被选中
function onSelected(item) {
  let payload = {};
  payload[prop] = {
    text:item.address_name,
    value:item.address_code
  }
  if (!type)
    store.commit("setRegisterCache",payload);
  else
    store.commit("setFormDataCache",payload);
  router.back();
}
</script>

<style lang="scss" scoped>
.address-list{
  height: 100vh;
}
iframe.address-list{
  width: 100%;
  height: calc(100vh - 10px);
  border: none;
}
</style>
