<template>
  <t-result :img="cur.img" :imgProps="imgProps" :hint="cur.hint" ref="resRef"
    hint-style="font-size:14rem;color:#7C7C7C;"
  >
    <div style="color: #105DF2;" @click="onHandle" ><van-icon name="replay" /> 刷新页面</div>
  </t-result>
</template>

<script setup>
import {useRouter,useRoute} from "vue-router";
import {onMounted} from "vue";
import tools from "../../utils/tools.js";
import {ref} from "vue";
import axios from "../../axios/index.js";

const router = useRouter();
const route = useRoute();
const resRef = ref();
let cur = ref({
  img:"order-05.png",
  title:"支付中...",
  hint:"支付完毕后即可报名成功",
});
tools.setWxTitle(cur.value.title)
const imgProps = {
  width:'150rem',
  height:'64rem',
  style:"padding:50rem 72rem"
};
function onHandle () {
  history.go(0);
}
onMounted(()=>{
  let flag = false;
  resRef.value.$el.querySelector("img").onload = function (e) {
    if (!flag){
      gotoPay();
      flag = true;
    }
  }
  // 3秒后图片还没加载出来 就不管图片了
  setTimeout(()=>{
    if (!flag) {
      gotoPay();
      flag = true;
    }
  },3000)
})
// 跳转支付处理
function gotoPay(){
  let query = route.query;
  if (query.pay_url){
    let url = query.pay_url;
    delete query.pay_url;
    let qq = "";
    for (let k in query){
      if (qq !== "") qq += "&"
      qq+=k+"="+query[k]
    }
    history.replaceState(null,null,location.origin+location.pathname+"?"+qq)
    window.location.href=url
  }else {
    getPayStatus()
  }
}

/**
 * @desc 延迟函数 , 只能在promise异步对象中使用
 * @param time
 * @return {Promise<unknown>}
 */
function delay(time){
  return new Promise(resolve => {
    setTimeout(()=>{
      resolve()
    },time)
  })
}

// 页面隐藏测试
var hiddenProperty = 'hidden' in document ? 'hidden' :
    'webkitHidden' in document ? 'webkitHidden' :
        'mozHidden' in document ? 'mozHidden' :
            null;
var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
var onVisibilityChange = tools.debounce(function(){
  console.log(!document[hiddenProperty] && route.name==='orderWait');
  if (!document[hiddenProperty]) {
    if (route.name==='orderWait')
      gotoPay()
  }else{
    console.log('页面非激活');
  }
},false,200)
document.addEventListener(visibilityChangeEvent, onVisibilityChange);
// 查询支付状态
function getPayStatus(){
  return new Promise(resolve=>{
    axios.post("/api/recruit/family/pay-status",{
      order_m:route.query.order_m
    }).then(async res=>{

      if (res.status === 0){
        await router.replace({path:"/order"})
      }else if(res.status === 1){
        await router.replace({
          path:"/order-status",
          query:{
            status:0,
            order_number:route.query.order_number
          }
        })
      }else{
        await delay(2000);
        getPayStatus();
      }
    })
  })
}
</script>

<style lang="scss" scoped>

</style>
