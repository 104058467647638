<template>
  <div class="pdf-preview">
    <vue-pdf-embed ref="pdfs" :source="state.source" :style="scale" @rendered="loaded" class="vue-pdf-embed" />
  </div>
</template>
<script setup>
import { reactive, onMounted, computed, ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
const pdfs = ref()
const props = defineProps({
  pdfUrl: {
    type: String,
    required: true
  }
})
const state = reactive({
  source: props.pdfUrl, // 预览pdf文件地址
  pageNum: 1, // 当前页面
  scale: 1, // 缩放比例
  numPages: 0 // 总页数
})

/**
 * @description 设置后三页的pdf长度
 */
function loaded() {
  let count = pdfs.value.pageCount
  pdfs.value.$el.children[count - 1].querySelector('canvas').style.height = 600 + 'rem'
  pdfs.value.$el.children[count - 2].querySelector('canvas').style.height = 600 + 'rem'
  pdfs.value.$el.children[count - 3].querySelector('canvas').style.height = 600 + 'rem'
}
onMounted(() => {})
const scale = computed(() => `transform:scale(${state.scale})`)
function lastPage() {
  if (state.pageNum > 1) {
    state.pageNum -= 1
  }
}
function nextPage() {
  if (state.pageNum < state.numPages) {
    state.pageNum += 1
  }
}
function pageZoomOut() {
  if (state.scale < 2) {
    state.scale += 0.1
  }
}
function pageZoomIn() {
  if (state.scale > 1) {
    state.scale -= 0.1
  }
}
</script>
<style lang="css" scoped>
.pdf-preview {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  background: rgb(66, 66, 66);
}

.vue-pdf-embed {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.pdf-preview {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  background-color: #e9e9e9;
}
.pdf-wrap {
  overflow-y: auto;
}
.vue-pdf-embed {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.page-tool {
  position: absolute;
  bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  background: rgb(66, 66, 66);
  color: white;
  border-radius: 19px;
  z-index: 100;
  cursor: pointer;
  margin-left: 50%;
  transform: translateX(-50%);
}
.page-tool-item {
  padding: 8px 15px;
  padding-left: 10px;
  cursor: pointer;
}
</style>
