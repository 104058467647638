
// 0: 开发/测试模板
// 1: 预发/生产模板
export default [
    {
        "dbaf6bf9df9445a496c2da43d6f910ab":{
            placeholder:"2023年达州鸿鹄高中教育咨询服务协议",
            name:"dz.html"
        },
        "9079b2cdd06a4af7a55a7b9f04206ac1":{
            placeholder:"2023年资阳鸿鹄高中教育咨询服务协议",
            name:"zy.html"
        },
        "d2a129c4a34e45c5944474c737193e29":{
            placeholder:"2023年青白江鸿鹄高中教育咨询服务协议",
            name:"qbj.html"
        },
    },
    {
        "39aee54602b8449180967d2252fe5df0":{
            placeholder:"2023年达州鸿鹄高中教育咨询服务协议",
            name:"dz.html"
        },
        "9cce98bf0d5244cda99a9cb7d670deec":{
            placeholder:"2023年资阳鸿鹄高中教育咨询服务协议",
            name:"zy.html"
        },
        "bc1f8b13156e45318ccc3c8ea820a80b":{
            placeholder:"2023年青白江鸿鹄高中教育咨询服务协议",
            name:"qbj.html"
        },
    },
]
/*

{
    "4e454bd1a37a4a79b0f88ffd8b209f18":{
    placeholder:"2023年达州鸿鹄高中教育咨询服务协议",
        name:"4e454bd1a37a4a79b0f88ffd8b209f18.html"
},
    "f989f0d8b61f4ebd87dee639f617a8c8":{
    placeholder:"2023年资阳鸿鹄高中教育咨询服务协议",
        name:"f989f0d8b61f4ebd87dee639f617a8c8.html"
},
    "403e634045b54ed794a02f04e37dc851":{
    placeholder:"2023年青白江鸿鹄高中教育咨询服务协议",
        name:"403e634045b54ed794a02f04e37dc851.html"
},
},*/
