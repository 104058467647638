<template>
  <div class="order-info">
    <div class="order">
      <div class="top-bg">
        <div class="bar">
          <div class="line">

          </div>
        </div>
      </div>
      <div class="content">
        <t-order :data="middleData" class="box">
          <template #header>
            <t-order :data="topData" class="header"></t-order>
          </template>
        </t-order>
      </div>
    </div>
    <div class="btns">
      <t-button @click="$router.back()">返回</t-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Dialog } from "vant";
import { useRoute, useRouter } from "vue-router";
import axios from "../../axios/index.js";
import tools from "../../utils/tools.js";

const router = useRouter();
const route = useRoute();
const hasOrder = ref(true);
const status = route.query.status && Number(route.query.status);

function formatData(data) {
  let res = {};
  data.forEach(item => {
    if (item == null) return;
    res[item.key] = item.val.join(" ");
  })

  return res;
}

let topData = ref(null), middleData = ref(null);
getData();

function getData() {
  axios.post('/api/recruit/family/order-formal-cost', { order_number: route.query.id }).then(res => {
    let data = res
    if (data.length > 0) {
      let topResource = [{ "key": data[0].name, "val": ['￥'+tools.formatMoney(data[0].price / 100)] }];

      topData.value = formatData(topResource)
      let middleResource = []
      for (const i in data) {
        if (i > 0) {
          middleResource.push({ "key": data[i].name, "val": ['￥'+tools.formatMoney(data[i].price / 100)] })

        }
      }
      middleData.value = formatData(middleResource)
    }

  })
  return;
  let msg = "有更新条款，请仔细阅读并签署协议"
  // 重新签署协议
  Dialog.alert({
    width: '294rem',
    title: "您签署的协议",
    message: `<div style="color:#000;font-size: 12rem;"><span style="color:#417FF7;">《初升高咨询服务协议》</span> ${ msg }</div>`,
    allowHtml: true,
    confirmButtonText: "马上签署",
    confirmButtonColor: "#417FF7"
  })
}
</script>

<style lang="scss" scoped>
.order-info {
  background-color: #F8F8F8;
  min-height: 100vh;

  .btns {
    padding: 0 30rem;
    padding-bottom: 30rem;
    --van-button-default-background-color: transparent;
    --van-button-default-border-color: #C7C7C7;
    --van-button-default-color: #7C7C7C;
  }
}

.order {
  margin-bottom: 32rem;

  .top-bg {
    background-color: #417FF7;
    height: 80rem;
    display: flex;
    justify-content: center;
    padding-top: 28rem;

    .bar {
      width: 356rem;
      height: 30rem;
      background-color: #105DF2;
      border-radius: 15rem;

      .line {
        margin: 10rem auto 0;
        width: 342rem;
        border-radius: 7rem;
        height: 14rem;
        background-color: rgba(116, 163, 255, 0.4);

      }
    }
  }

  .content {
    padding: 0 14rem;
    margin-top: -60rem;
    position: relative;
    z-index: 2;

    .header {
      padding-top: 5rem;

      .number {
        font-weight: 600;
      }

      :deep(.t-order-info .row) {
        font-size: 14rem;
        font-weight: 600;

      }

      :deep(>.t-order-info) {
        padding: 0;
      }
    }

    .footer {
      padding: 5rem 0;
      border-top: 2px dashed #ccc;
    }
  }
}
</style>
