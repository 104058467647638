<template>
  <div id="my_app" v-if="show" @click="openDevModel">
    <van-progress :stroke-width="2" track-color="white" style="position: fixed;width: 100%;top:0;left:0;z-index: 9999;" pivot-color="#f00" :percentage="store.state.progress" v-show="store.state.progress!==0" :show-pivot="false"/>
    <t-loading v-model:show="store.state.loading"></t-loading>
    <van-pull-refresh v-if="$route.meta.pullRefresh" @refresh="$router.go(0)" >
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
      </router-view>
    </van-pull-refresh>
    <router-view v-else v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
    </router-view>
  </div>
  <div id="my_app" v-else @click="openDevModel" style="text-align: center;margin: auto;">
    <div style="display: flex;justify-content: center;padding: 24px 0">
      <div style="width: 100px;height: 100px;border-radius: 50px;background: #10aeff;">
        <div style="display:flex;justify-content: center;flex-wrap:wrap;">
          <div style="width: 20px;height: 20px;background: #fff;border-radius: 50px;margin-top: 6px"></div>
          <div style="width: 100%;margin: 6px 0"></div>
          <div style="width: 10px;height: 50px;background: #fff;border-radius: 1px;"></div>
        </div>
      </div>
    </div>
    请在微信客户端打开链接
  </div>
</template>

<script setup>
import {useStore} from "vuex"
import {ref} from "vue";
import VConsole from "vconsole";
import {checkBrowser} from "./utils/utils.js";
import {computed} from "vue";
import {useRoute} from "vue-router";
const href = ref("")
const store = useStore()
const route = useRoute();
const getters = store.getters;
const show = computed(()=>{
  if (["home","error"].includes(route.name)){
    return true;
  }
  return getters.microMessenger.flag;
})
/**
 * 检验是否是移动设备
 * @returns {boolean}
 */
let system = {
  win: false,
  mac: false,
  xll: false,
  ipad: false
};
let p = navigator.platform;
system.win = p.indexOf('Win') === 0;
system.mac = p.indexOf('Mac') === 0;
system.x11 = p === 'X11' || p.indexOf('Linux') === 0;
system.ipad = navigator.userAgent.match(/iPad/i) != null;
checkBrowser();
// 非正式环境开启
function setDev(e) {
  if (import.meta.env.MODE.indexOf('dev') === -1 && import.meta.env.MODE.indexOf("test") === -1)return ()=>{}
  let i = 0;
  let timer = null;
  let vc = null
  return function () {
    i ++ ;
    if (timer != null)
      clearInterval(timer);
    if (i === 10 ){
      if (!getters.microMessenger.flag)
        store.commit("setMicroMessenger",{flag: true});
      if (!system.win && !system.mac && vc == null)
        vc = new VConsole()
    }
    timer = setTimeout(()=>{
      i = 0;
    },500)
  }
}
let openDevModel = setDev();
</script>

<style lang="scss" scoped>
#my_app {
  background: white;
  min-height: 100vh;
}
</style>
