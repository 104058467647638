<template>
<div class="successful">
  <div class="top">
    <t-img src="register-02.png" width="172rem" height="140rem" style="padding: 12rem 0;"></t-img>
  </div>
  <div class="content">
    <t-order class="order" :data="baseInfo"></t-order>
    <t-order class="order" :data="orderInfo"></t-order>
  </div>
  <div class="btns">
    <t-button class="btn" type="primary" @click="showContract">查看完整协议内容</t-button>
    <t-button class="btn" @click="router.push({path:'/order-info',query:{id:order_number}})">查看更多订单信息</t-button>
  </div>
</div>
</template>

<script setup>
import {computed, ref} from "vue";
import tools from "../../utils/tools.js";
import {useRoute, useRouter} from "vue-router";
import axios from "../../axios/index.js";
import {showLoading} from "../../utils/utils.js";
const route = useRoute();
const router = useRouter();
const flow_id = route.query.flow_id;
let baseInfo = computed(()=>formatData(baseData));
let orderInfo = computed(()=>formatData(orderData));
let url = ref("");
let baseData = ref([]);
let orderData = ref([]);
let order_number = ref("");
showLoading(true);
function showContract(){
  window.location.href=url.value
}
axios.post("/api/recruit/family/contract-success",{flow_id}).then(res=>{
  const {service_name,
    school_name,
    customer_name,
    student_name,
    agreement_sn,
    agreement_name,
    pay_success,
    sign_time,
    money} = res;
  order_number.value = res.order_number;

  url.value = res.url;
  orderData.value = [
    {
      "key": "报名人姓名",
      "isTitle": false,
      "val": [
        customer_name
      ]
    },
    {
      "key": "学生姓名",
      "isTitle": false,
      "val": [
        student_name
      ]
    },
    {
      "key": "协议编号",
      "isTitle": false,
      "val": [
        agreement_sn
      ]
    },
    {
      "key": "协议名称",
      "isTitle": false,
      "val": [
        agreement_name
      ]
    },
    {
      "key": "报名成功时间",
      "isTitle": false,
      "val": [
        sign_time
      ]
    },
    {
      "key": "支付成功时间",
      "isTitle": false,
      "val": [
        pay_success
      ]
    },
    {
      "key": "支付金额",
      "isTitle": false,
      "val": [
        '￥'+tools.formatMoney(money/100)
      ]
    }
  ]
  baseData.value = [
    {
      "key": "服务名称",
      "isTitle": false,
      "val": [
        service_name
      ]
    },
    {
      "key": "报名学校",
      "isTitle": false,
      "val": [
        school_name
      ]
    }
  ]
  showLoading(false);
}).catch(err=>{
  showLoading(false);
})
function formatData(data){
  let res = {};
  data.value.forEach(item=>{
    res[item.key] = item.val.join(" ");
  })
  return res;
}
</script>

<style lang="scss" scoped>
.successful{
  min-height: 100vh;
  background-color: #F8F8F8;
  .top{
    background-color: #F8F8F8;
    text-align: center;
  }
  .content{
    padding: 0 12rem;
    .order{
      margin-bottom: 12rem;
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }
  .btns{
    padding:0 40rem;
    margin-top: 40rem;
    .btn{
      margin-top: 20rem;
    }
  }
}
</style>
