import Popup from "./popup.vue";
import {render,h} from "vue"



function mount(){

    let $this = {
        instance:null,
        _options:{
            closeOnClickOverlay:true,
            closeOnBack:true,
            render(){
                return "123123"
            }
        },
        show(options = {}){
            let opt = Object.assign(this._options,options);
            this._options = opt;
            this._render({
                show:true,
                onClickOverlay(){
                    if (opt.closeOnClickOverlay && !opt.onClickOverlay){
                        $this.hide();
                    }else if (opt.onClickOverlay){
                        opt.onClickOverlay($this.hide);
                    }
                }
            });
        },
        hide(){
            if (!this.instance)return;
            this.instance.remove();
            this.instance = null;
        },
        _render(options = {}){
            let com = h(Popup,options,{
                default(){
                    let render = $this._options.render;
                    if (typeof render === "function")render = render();
                    else if(typeof render === "string")return h('div',{innerHTML:render})
                    return render;
                }
            });
            let dom = document.createElement('div');
            render(com,dom);
            this.instance = dom;
            document.getElementById("my_app").append(dom);
        }
    }
    const pps = function () {
        if ($this._options.closeOnBack){
            $this.hide();
        }
    }
    window.removeEventListener('popstate',pps);
    window.addEventListener('popstate',pps);
    return $this;
}

export default mount();
