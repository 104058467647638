import tools from "./tools"
let {setSession,getSession} = tools;
import router from "../router/index"
/*export type TState = {
    back?:string,
    current:string,
    forward?:string,
    replaced:boolean,
    position:number
}
interface IRecordRouter{
    push(router:TState):void
    pop():void
    replace(router:TState):void
    getHistoryList():Array<TState>
    clearHistory():void
    _save():void
    getInstance():RecordRouter
}*/
/*class RecordRouter implements IRecordRouter{
    private sessionKey = "history";
    static instance?:RecordRouter = null;
    public push(router: TState) {
        let stack:TState[] = getSession(this.sessionKey) || [];
        stack.push(router);
        this._save(stack);
    }
    public pop() {
        let stack:TState[] = getSession(this.sessionKey) || [];
        if (stack.length > 0)
            stack.pop();
        this._save(stack)
    }
    private _save(stack:TState[]) {
        setSession(this.sessionKey,stack)
    }
    public replace(router: TState) {
        let stack:TState[] = getSession(this.sessionKey) || [];
        stack[stack.length-1] = router;
        this._save(stack);
    }
    public getHistoryList(): Array<TState> {
        return getSession(this.sessionKey) as Array<TState> || [];
    }
    public clearHistory() {
        setSession(this.sessionKey,[]);
    }
    static getInstance(): RecordRouter {
        if (this.instance)return this.instance
        else return new RecordRouter();
    }
}
export const recordRouter = RecordRouter.getInstance();*/


/**项目使用方法*/
export function removePopstate(store){
    store.commit("setPopstate",null);
    window.onpopstate = store.state.popstate;
}
export function addPopstate(store,fn){
    // recordRouter.pop();
    store.commit("setPopstate",fn);
    window.onpopstate = store.state.popstate;
}
export class HistoryBack{
    constructor(callBack=()=>{},canBack = true) {
        this.removeHistoryBack();
        this.canBack = canBack;
        history.pushState("","",document.URL);
        // window.onpopstate = null;
        window.onpopstate =  ()=> {
            history.pushState("","",document.URL);
            if (this.canBack){
                history.go(-2);
            }else{
                callBack(this);
            }
        }
    }

    /**
     * @desc 清除返回监听
     */
    removeHistoryBack(){
        window.onpopstate = null;
    }

    /**
     * @description 返回
     * @param num 与go方法一样
     */
    back(num = -2){
        this.canBack = true;
        this.removeHistoryBack();
        window.history.go(num);
    }

    /**
     * @description 清空页面栈
     * @param path
     */
    clearHistory(path){
        setTimeout(()=>{
            let host = location.origin;
            let len = history.length -2;
            this.canBack = true;
            this.removeHistoryBack();
            router.go(-len);
            router.replace(path);
        },20)
    }
}
export function onHistoryBack(callBack=()=>{},canBack){
   return new HistoryBack(callBack,canBack);
}
